import React, { forwardRef } from 'react'
import { Close } from '@mui/icons-material'
import Drawer from '@mui/material/Drawer'
import moment from 'moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  styled,
  Typography,
  Grid
} from '@mui/material'
import DonorHistory from './DonorHistory'

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />
})

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',

  padding: theme.spacing(3, 4),
  justifyContent: 'space-between'
}))

const label = {
  color: '#1B2124',
  fontSamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  paddingBottom: '5px'
}
const labelvalue = {
  color: ' #07101C',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '24px',
  paddingBottom: '8px',
  borderBottom: '1px solid #F0F1F3',
  marginBottom: '16px'
}
const labelvaluePending = {
  color: 'var(--ehr-secondary-d-0, #214F8A)',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '24px',
  paddingBottom: '8px',
  borderBottom: '1px solid #F0F1F3',
  marginBottom: '16px'
}

const boxsetion = {
  marginBottom: '10px',
  color: '#303F9F',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  borderRadius: '8px',
  padding: '10px 8px',
  background: ' var(--ehr-background, #F2F9F7)'
}

function DonorHistoryModal(props) {
  // ** props
  const { closeDialog, data, open, title, size, scroll, id, fullScreen, side, closeAble } = props
  //  console.log(data)
  return (
    <Drawer
      open={open}
      anchor={side || 'right'}
      variant='temporary'
      onClose={() => closeDialog()}
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': { width: 400 },
        padding: '16px'
      }}
    >
      <Box sx={{ margin: '16px' }}>
        <Header sx={{ borderRadius: '8px', backgroundColor: '#F2F9F7', padding: '8px', marginBottom: '8px' }}>
          <Box>
            <Typography variant='h6' sx={{ color: ' #303F9F', fontWeight: '500' }}>
              {data?.name}
            </Typography>
            <Typography variant='h6' sx={{ color: '#6B7280', fontWeight: '500', fontSize: '14px' }}>
              {data?.subheading}
            </Typography>
          </Box>
          <Box>
            <Close fontSize='small' onClick={() => closeDialog()} sx={{ cursor: 'pointer' }} />
          </Box>
        </Header>

        <Grid container>
          {data?.basic?.map((element, i) => (
            <Grid item xs={6} key={i}>
              <Typography sx={label}>{element.label}</Typography>
              <Typography sx={labelvalue}>{element.values}</Typography>
            </Grid>
          ))}
        </Grid>

        <Box sx={boxsetion} xs={12}>
          Current Donation status
        </Box>
        <Grid container>
          {data?.curr_status?.map((element, i) => (
            <Grid item xs={6} key={i}>
              <Typography sx={label}>{element.label}</Typography>
              {element.values === 'Pending' ? (
                <Typography sx={labelvaluePending}>{element.values}</Typography>
              ) : (
                <Typography sx={labelvalue}>{element.values}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Drawer>
  )
}

export default DonorHistoryModal
