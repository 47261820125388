import React, { useState } from 'react'
import { textMargin } from './ReportComp'
import { BorderRight } from '@mui/icons-material'
import { useAppContext } from '../../AppContext'
import { BloogGroupPrintRHFn } from '../shared/BloogGroupPrintRH'

const gridItemTitle = {
  color: '#205B8D',
  width: '100px',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '16.94px',
  ...textMargin
}
const gridItem = {
  flex: 1,
  borderLeft: '1px dotted #205B8D',
  padding: '0px 8px',
  display: 'grid',
  gap: '8px',
  gridTemplateColumns: 'repeat(4, 1fr)'

  //   'auto auto auto auto'
}

function Info({ title, desc }) {
  return (
    <div
      style={{
        borderTop: '2px solid #14B2A8',
        background: '#EDF7F7',
        minHeight: '43px',
        padding: '6px',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        borderRadius: '0px 0px 8px 8px'
      }}
    >
      <p
        style={{
          ...textMargin,
          color: '#205B8D',
          fontSize: '10px',
          fontWeight: '400',
          lineHeight: '12.1px'
        }}
      >
        {title || 'title'}
      </p>
      <p
        style={{
          ...textMargin,
          color: '#000000',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '14.52px'
        }}
      >
        {desc || 'title'}
      </p>
    </div>
  )
}

const ReportCompDetail = ({ data, ttiresult, failreasion, tenant }) => {
  return (
    <div
      style={{
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        background: '#ffffff',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: '400',
        minHeight: '100vh',
        position: 'relative'
      }}
    >
      <style type='text/css' media='print'>
        {
          '\
 @page { size: portrait; }\
'
        }
      </style>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: '10px 16px',
          justifyContent: 'space-between',
          zIndex: 1,
          background: 'linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%)'
        }}
      >
        <img
          style={{
            height: '36px',
            objectFit: 'contain'
          }}
          alt=''
          src='/assets/images/tti/mainlogo.svg'
        />
        <img
          style={{
            height: '45px',
            objectFit: 'contain'
          }}
          alt=''
          src={tenant?.logo || '/assets/images/tti/sidelogo.svg'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '10px',
          padding: '16px',
          flex: 1
        }}
      >
        <div
          style={{
            background: '#F6FBFB',
            minHeight: '65px',
            display: 'flex',
            gap: '3px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '6px 16px',
            borderRadius: '8px 8px 0px 0px'
          }}
        >
          <p style={gridItemTitle}> Blood Information</p>
          <div style={gridItem}>
            <Info title='Blood Bag Number' desc={data?.BloodBagIssueEntity?.bag_number || 'N/A'} />
            <Info title='Blood Group' desc={ BloogGroupPrintRHFn(data?.BloodBagIssueEntity?.BloodTestingEntity?.blood_group || 'N/A')} />
          </div>
        </div>
        <div
          style={{
            background: '#F6FBFB',
            minHeight: '116px',
            display: 'flex',
            gap: '3px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '6px 16px',
            borderRadius: '8px 8px 0px 0px'
          }}
        >
          <p style={gridItemTitle}>TTI Kit Information</p>
          <div style={gridItem}>
            <Info title='HIV Kit' desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.hivkit || 'N/A'} />
            <Info
              title='Hepatitis-B Kit'
              desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisbkit || 'N/A'}
            />
            <Info
              title='Hepatitis-C Kit'
              desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisckit || 'N/A'}
            />
            <Info title='Malaria Kit' desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.malariakit || 'N/A'} />
            <Info
              title='Syphilis(VDRL) Kit'
              desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.syphiliskit || 'N/A'}
            />
          </div>
        </div>
        <div
          style={{
            background: '#F6FBFB',
            minHeight: '116px',
            display: 'flex',
            gap: '3px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '6px 16px',
            borderRadius: '8px 8px 0px 0px'
          }}
        >
          <p style={gridItemTitle}>TTI Information</p>
          <div style={gridItem}>
            <Info
              title='Syphilis(VDRL)'
              desc={
                data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1' ? (
                  <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                ) : (
                  'Non-Reactive'
                )
              }
            />
            <Info
              title='Anti-HCV(Hepatitis C)'
              desc={
                data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1' ? (
                  <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                ) : (
                  'Non-Reactive'
                )
              }
            />
            <Info
              title='HBsAg(Hepatitis B)'
              desc={
                data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1' ? (
                  <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                ) : (
                  'Non-Reactive'
                )
              }
            />
            <Info
              title='HIV'
              desc={
                data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1' ? (
                  <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                ) : (
                  'Non-Reactive'
                )
              }
            />
            <Info
              title='Malaria'
              desc={
                data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1' ? (
                  <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Positive</span>
                ) : (
                  'Non-Reactive'
                )
              }
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            padding: '16px',
            flex: 1
          }}
        >
          <img
            style={{
              height: '180px',
              objectFit: 'contain'
            }}
            alt=''
            src={`/assets/images/tti/${ttiresult ? 'pass' : 'fail'}.svg`}
          />
          <p
            style={{
              color: '#000000',
              fontSize: '34px',
              fontWeight: '700',
              lineHeight: '41px',
              ...textMargin
            }}
          >
            TTI Test {ttiresult ? 'Pass' : 'Fail'}
          </p>

          {!ttiresult ? (
            <p
              style={{
                color: '#7B7B7B',
                fontSize: '10px',
                fontWeight: '400',
                lineHeight: '16px',
                ...textMargin
              }}
            >
              <ul>{failreasion?.length > 0 ? failreasion?.map((ele, idx) => <li key={idx}>{ele}</li>) : null}</ul>
            </p>
          ) : null}
        </div>
      </div>

      <div
        style={{
          minHeight: '70px',
          padding: '6px 16px',
          background: '#F6FBFB',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <p
          style={{
            color: '#7B7B7B',
            fontSize: '10px',
            fontWeight: '400',
            lineHeight: '16px',
            ...textMargin
          }}
        >
          All laboratory results require clinical interpretation by a medical professional. This report is not intended
          for medico-legal purposes. For any concerns regarding the report, please contact us here
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            color: '#214F8A'
          }}
        >
          <p
            style={{
              fontSize: '10px',
              margin: '0px'
            }}
          >
            Call: <span style={{ color: '#111827' }}>{tenant?.contact_number || ''}</span>
          </p>
          <p
            style={{
              fontSize: '10px',
              margin: '0px'
            }}
          >
            Email : <span style={{ color: '#111827' }}>{tenant?.email || ''}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ReportCompDetail
