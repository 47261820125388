import { createColumnHelper } from '@tanstack/react-table'
import ReactTable, { SortingImg } from '../../shared/ReactTable'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ProfileBagRedirect } from '../../donors/profile/utils/shared/profile-bag-redirect'
import { StatusCapsule } from '../../donors/profile/utils/shared/status-capsule'
import BloogGroupPrintRH from '../../shared/BloogGroupPrintRH'
import { InfoItem } from '../../donors/profile/utils/shared/info-item'
import { testMappings } from '../../donors/profile/utils/ui/tti'
import PaginationLink from '../../pagination/PaginationLink'
import AxiosHelper from '../../../helper/AxiosHelper'
import { checkTenantId, debouncing } from '../../../helper/CommonAPI'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormProvider from '../../shared/FormProvider'
import { inputStyles } from '../../certification/EditCertificate'
import { DocumentScanner, Download, PictureAsPdf, SearchOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, TextField, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { downLoadFiles } from '../../middleware/auth'
import { active } from '../../blood-requisition/Filters'
import { ReportPreview } from '../../common/report-preview'

export const DonorTable = ({ data, setData, id, pageData, setLoading }) => {
  const [currSearchParams, setCurrSearchParams] = useState({})
  const [filterEnable, setFilterEnable] = useState(false)
  const [sorting, setSorting] = useState([])
  const [currentFilter, setCurrentFilter] = useState(2)
  const [searchQuery, setSerchQuery] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  const history = useHistory()

  const schema = yup.object({
    search: yup.string().required('Search is required').max(50, 'Search must be at most 50 characters')
  })

  const fetchFilteredData = async url => {
    try {
      setLoading(true)
      url = checkTenantId(url)
      const res = await AxiosHelper.get(url)
      const { data } = res
      if (!data) {
        throw Error("Can't fetch donor info!!")
      }

      setData(data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const tableColumns = useMemo(() => {
    const tableColumnHelper = createColumnHelper()

    const columns = [
      tableColumnHelper.accessor('orderByBagNumber', {
        cell: ({ cell }) => (
          <div className='requestid-name'>
            <div className='primary-request-text'>
              <ProfileBagRedirect bag_id={cell?.row.original?.id}>
                {cell?.row.original?.bag_number || '-'}
                <div className='primary-request-text'>{cell?.row.original?.bag_type || '-'}</div>
              </ProfileBagRedirect>
            </div>
          </div>
        ),
        header: info => (
          <div onClick={info.header.column.getToggleSortingHandler()}>
            Bag Number
            <SortingImg column={info.header.column} />
          </div>
        )
      }),
      tableColumnHelper.accessor('orderByDonorName', {
        cell: ({ cell }) => (
          <div>
            <ProfileBagRedirect donor_id={cell?.row.original?.PatientDonorEntity?.id}>
              <div>
                <span data-text={cell?.row.original?.PatientDonorEntity?.name}>
                  {cell?.row.original?.PatientDonorEntity?.name || '-'}
                </span>
                <span className='hover-image'>
                  <img
                    src={'/assets/images/Patient Info-1.svg'}
                    style={{
                      width: '20px',
                      height: '20px',
                      position: 'relative',
                      bottom: '3px'
                    }}
                    className='hover-image'
                    alt='info'
                  />
                  <div className='info'>
                    <div className='mt-2'>
                      <span className='info-label'>Father/Husband Name :</span>{' '}
                      {cell?.row.original?.PatientDonorEntity?.father_name || '-'}
                    </div>
                    <div className='mt-2'>
                      <span className='info-label'>Mobile :</span>{' '}
                      {cell?.row.original?.PatientDonorEntity?.mobile || '-'}
                    </div>
                    <div className='mt-2'>
                      <span className='info-label'>Email :</span> {cell?.row.original?.PatientDonorEntity?.email || '-'}
                    </div>
                    <div className='mt-2'>
                      <span className='info-label'>State :</span> {cell?.row.original?.PatientDonorEntity?.state || '-'}
                    </div>{' '}
                    <div className='mt-2'>
                      <span className='info-label'>District :</span>{' '}
                      {cell?.row.original?.PatientDonorEntity?.district || '-'}
                    </div>
                  </div>
                </span>
              </div>

              {/* <div className='secondary-request-text'>
                {cell?.row.original?.PatientDonorEntity?.blood_group ? (
                  <BloogGroupPrintRH bloodGroup={cell?.row.original?.PatientDonorEntity?.blood_group} />
                ) : (
                  '-'
                )}
              </div> */}
            </ProfileBagRedirect>
          </div>
        ),
        header: info => (
          <div onClick={info.header.column.getToggleSortingHandler()}>
            Donor Name
            <SortingImg column={info.header.column} />
          </div>
        )
      }),
      tableColumnHelper.accessor('blood_group', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.PatientDonorEntity?.blood_group ? (
              <BloogGroupPrintRH bloodGroup={cell?.row.original?.PatientDonorEntity?.blood_group} />
            ) : (
              '-'
            )}
          </div>
        ),
        header: info => <div>Blood Group</div>
      }),
      tableColumnHelper.accessor('age_sex', {
        cell: ({ cell }) => (
          <div>
            <span>
              {cell?.row.original?.PatientDonorEntity?.age ? `${cell?.row.original?.PatientDonorEntity?.age} & ` : '-'}
            </span>
            <span>{cell?.row.original?.PatientDonorEntity?.gender || '-'}</span>
          </div>
        ),
        header: info => <div>Age & Sex</div>
      }),

      // tableColumnHelper.accessor('ttiStatus', {
      //   cell: ({ cell }) => (
      //     <span
      //       style={{
      //         display: 'flex',
      //         justifyContent: 'flex-start',
      //         alignItems: 'center',
      //         gap: '1rem'
      //       }}
      //     >
      //       <span>
      //         {!cell.row.original?.BloodTestingEntity ? (
      //           <div
      //             style={{
      //               cursor: 'pointer'
      //             }}
      //             onClick={() => history.push(`/donors-detail/testing/${data?.abha_id}`)}
      //             className='pending'
      //           >
      //             <StatusCapsule status={'pending'}>Pending</StatusCapsule>
      //           </div>
      //         ) : !cell.row.original?.BloodTestingEntity?.is_declined ? (
      //           <div
      //             className='pending'
      //             style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      //             // onClick={() => {
      //             //   if (!data.is_bulk) {
      //             //     setPrintOptions('print_TTI_Report')
      //             //     setOpenModal(true)
      //             //   }
      //             // }}
      //           >
      //             {/* <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} /> */}
      //             <StatusCapsule status={'done'}>Non-Reactive</StatusCapsule>
      //           </div>
      //         ) : (
      //           <StatusCapsule status={'cancel'}>Reactive</StatusCapsule>
      //         )}
      //       </span>
      //       <span className='hover-image'>
      //         <img
      //           src={'/assets/images/Patient Info-1.svg'}
      //           style={{
      //             width: '20px',
      //             height: '20px',
      //             position: 'relative',
      //             bottom: '3px'
      //           }}
      //           className='hover-image'
      //           alt='info'
      //         />
      //         <div className='info'>
      //           {testMappings.map(({ title, key, kitKey }, idx) => (
      //             <>
      //               <div key={title} className='mt-2'>
      //                 <span className='info-label'>{title} :</span>{' '}
      //                 {cell?.row.original?.BloodTestingEntity?.[key] == '0' ? (
      //                   <span>
      //                     {cell?.row.original?.BloodTestingEntity?.[kitKey] === 'ELISA Kit'
      //                       ? 'Non-Reactive'
      //                       : 'Negative'}
      //                   </span>
      //                 ) : cell?.row.original?.BloodTestingEntity?.[key] == '1' ? (
      //                   <span>
      //                     {cell?.row.original?.BloodTestingEntity?.[kitKey] === 'ELISA Kit' ? 'Reactive' : 'Positive'}
      //                   </span>
      //                 ) : (
      //                   '-'
      //                 )}
      //               </div>
      //             </>
      //           ))}
      //         </div>
      //       </span>
      //     </span>
      //   ),
      //   header: info => <div>TTI Status</div>
      // }),

      tableColumnHelper.accessor('BloodTestingEntity', {
        cell: ({ cell }) => (
          <div>
            {cell?.row.original?.BloodTestingEntity ? (
              <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                <div>
                  {cell?.row.original?.BloodTestingEntity?.vdrl ? (
                    cell?.row.original?.BloodTestingEntity?.vdrl === '0' ? (
                      <StatusCapsule status={'done'}>
                        {cell?.row.original?.BloodTestingEntity?.syphiliskit === 'ELISA Kit'
                          ? 'Non-Reactive'
                          : 'Negative'}
                      </StatusCapsule>
                    ) : (
                      <StatusCapsule status={'cancel'}>
                        {cell?.row.original?.BloodTestingEntity?.syphiliskit === 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </StatusCapsule>
                    )
                  ) : (
                    <StatusCapsule status={'done'}>Non-Reactive</StatusCapsule>
                  )}
                </div>
                <div>
                  {cell?.row.original?.BloodTestingEntity?.malaria ? (
                    cell?.row.original?.BloodTestingEntity?.malaria === '0' ? (
                      <StatusCapsule status={'done'}>
                        {cell?.row.original?.BloodTestingEntity?.malariakit === 'ELISA Kit'
                          ? 'Non-Reactive'
                          : 'Negative'}
                      </StatusCapsule>
                    ) : (
                      <StatusCapsule status={'cancel'}>
                        {cell?.row.original?.BloodTestingEntity?.malariakit === 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </StatusCapsule>
                    )
                  ) : (
                    <StatusCapsule status={'done'}>Negative</StatusCapsule>
                  )}
                </div>
                <div>
                  {cell?.row.original?.BloodTestingEntity?.hiv ? (
                    cell?.row.original?.BloodTestingEntity?.hiv === '0' ? (
                      <StatusCapsule status={'done'}>
                        {cell?.row.original?.BloodTestingEntity?.hivkit === 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}
                      </StatusCapsule>
                    ) : (
                      <StatusCapsule status={'cancel'}>
                        {cell?.row.original?.BloodTestingEntity?.hivkit === 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </StatusCapsule>
                    )
                  ) : (
                    <StatusCapsule status={'done'}>Non-Reactive</StatusCapsule>
                  )}
                </div>
                <div>
                  {cell?.row.original?.BloodTestingEntity?.hsbg ? (
                    cell?.row.original?.BloodTestingEntity?.hsbg === '0' ? (
                      <StatusCapsule status={'done'}>
                        {cell?.row.original?.BloodTestingEntity?.hepatitisbkit === 'ELISA Kit'
                          ? 'Non-Reactive'
                          : 'Negative'}
                      </StatusCapsule>
                    ) : (
                      <StatusCapsule status={'cancel'}>
                        {cell?.row.original?.BloodTestingEntity?.hepatitisbkit === 'ELISA Kit'
                          ? 'Reactive'
                          : 'Positive'}
                      </StatusCapsule>
                    )
                  ) : (
                    <StatusCapsule status={'done'}>Non-Reactive</StatusCapsule>
                  )}
                </div>
                <div>
                  {cell?.row.original?.BloodTestingEntity?.anty_hcv ? (
                    cell?.row.original?.BloodTestingEntity?.anty_hcv === '0' ? (
                      <StatusCapsule status={'done'}>
                        {cell?.row.original?.BloodTestingEntity?.hepatitisckit === 'ELISA Kit'
                          ? 'Non-Reactive'
                          : 'Negative'}
                      </StatusCapsule>
                    ) : (
                      <StatusCapsule status={'cancel'}>
                        {cell?.row.original?.BloodTestingEntity?.hepatitisckit === 'ELISA Kit'
                          ? 'Reactive'
                          : 'Positive'}
                      </StatusCapsule>
                    )
                  ) : (
                    <StatusCapsule status={'done'}>Negative</StatusCapsule>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                <div>-</div>
                <div>-</div>
                <div>-</div>
                <div>-</div>
                <div>-</div>
              </div>
            )}
          </div>
        ),
        header: info => (
          <div>
            <div className='text-center mb-2'>Status of Testing</div>
            <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
              <div>Syphilis</div>
              <div>Malaria</div>
              <div>HIV</div>
              <div>Hepatitis B</div>
              <div>Hepatitis C</div>
            </div>
          </div>
        )
      }),

      // Dynamic columns generated from `testMappings`
      // ...testMappings.map(({ title, key, kitKey }) =>
      //   tableColumnHelper.accessor(key, {
      //     cell: ({ cell }) => {
      //       const value = cell?.row.original?.BloodTestingEntity?.[key]
      //       const kitValue = cell?.row.original?.BloodTestingEntity?.[kitKey]

      //       return value === '0' ? (
      //         <span>{kitValue === 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}</span>
      //       ) : value === '1' ? (
      //         <span>{kitValue === 'ELISA Kit' ? 'Reactive' : 'Positive'}</span>
      //       ) : (
      //         '-'
      //       )
      //     },
      //     header: () => <div>{title}</div>
      //   })
      // ),
      // tableColumnHelper.accessor('state', {
      //   cell: ({ cell }) => (
      //     <div className='bloodrequisition-min-width'>
      //       <span>{cell?.row.original?.PatientDonorEntity?.state || '-'}</span>
      //     </div>
      //   ),
      //   header: info => (
      //     <div onClick={info.header.column.getToggleSortingHandler()}>
      //       State
      //       <SortingImg column={info.header.column} />
      //     </div>
      //   )
      // }),
      // tableColumnHelper.accessor('district', {
      //   cell: ({ cell }) => (
      //     <div className='bloodrequisition-min-width'>
      //       <span>{cell?.row.original?.PatientDonorEntity?.district || '-'}</span>
      //     </div>
      //   ),
      //   header: info => (
      //     <div onClick={info.header.column.getToggleSortingHandler()}>
      //       District
      //       <SortingImg column={info.header.column} />
      //     </div>
      //   )
      // }),
      tableColumnHelper.accessor('orderByAssignDate', {
        cell: ({ cell }) => (
          <div>
            <span>
              {cell?.row.original?.assigned_date
                ? dayjs(cell.row.original.assigned_date).format('DD-MMM-YYYY - HH:mm')
                : '-'}
            </span>
          </div>
        ),
        header: info => (
          <div onClick={info.header.column.getToggleSortingHandler()}>
            Collection Date & Time
            <SortingImg column={info.header.column} />
          </div>
        )
      })
    ]

    return columns
  }, [])

  const handleApplyFilter = (values, type, pageNumber) => {
    console.log(sorting)
    let filterurl = ''
    setCurrSearchParams(values)
    if (sorting && sorting?.length > 0) {
      if (sorting[0]?.id) {
        filterurl = filterurl + `&${sorting[0]?.id}=${sorting[0]?.desc ? 'desc' : 'asc'}`
      }
    }

    if (values?.searchQuery) {
      filterurl = filterurl + `&search=${values?.searchQuery}`
      console.log(`search query -> `, filterurl)
    }

    if (type === 'page' && pageNumber) {
      filterurl = filterurl + `&page=${pageNumber}`
    }

    if (filterurl?.length > 0) {
      let stringWithoutFirstLetter = filterurl.slice(1)
      fetchFilteredData(`blood-search/${id}/camp-donor-list?` + stringWithoutFirstLetter)
    } else {
      setFilterEnable(false)
      fetchFilteredData(`blood-search/${id}/camp-donor-list`)
    }
  }

  const methods = useForm({
    defaultValues: {
      search: ''
    },
    mode: 'onChange',
    resolver: yupResolver(schema)
  })
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setFocus: setErrorFocus,
    setError: setForrmError,
    reset,
    formState: { errors, isValid }
  } = methods

  const handleResetFilter = () => {
    // setSearchParams(initialFil)
    setCurrSearchParams({})
    setSerchQuery('')
  }
  const updateAll = useCallback(
    debouncing(searchValue => {
      let check = {
        ...currSearchParams,
        searchQuery: searchValue
      }
      handleApplyFilter(check, 'search')
    }, 500),
    [currSearchParams]
  )

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const onSubmit = async data => {
    console.log(`camp -> `, data)
    updateAll(data?.search)
  }

  useEffect(() => {
    handleApplyFilter(currSearchParams, 'apicall')
  }, [currentFilter, sorting])

  return (
    <div>
      <div className='donor-inventory-search'>
        <div className='search-and-filter-container'>
          <div className='search-container add-dnr-search '>
            <input
              value={searchQuery}
              onChange={e => {
                updateAll(e.target.value)
                setSerchQuery(e.target.value)
              }}
              className='search-input'
              type='text'
              placeholder='Search by Bag No.'
            />
            <button
              type='submit'
              style={{
                border: 0,
                padding: 0
              }}
            >
              <img src={'/assets/images/search-icon.svg'} alt='search-icon' />
            </button>
          </div>
        </div>
        <div className='flex'>
          <Tooltip title='Download'>
            <IconButton color='primary' sx={{ ...active, borderRadius: 1 }} onClick={handleDropdownOpen}>
              <Download color='white' />
            </IconButton>
            {/* <span className='ml-1'>Download</span> */}
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleDropdownClose()}
            sx={{ '& .MuiMenu-paper': { width: 290, mt: 1 } }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <ReportPreview
              url={`/blood-testing/camp-multiple-tti-report/${id}`}
              filename={'Camp_donors_tti_report'}
              title={'Camp Donor TTI Report'}
            >
              <MenuItem sx={{ pl: 2 }}>
                <ListItemIcon>
                  <PictureAsPdf />
                </ListItemIcon>{' '}
                Donors TTI Reports
              </MenuItem>
            </ReportPreview>

            <ReportPreview
              url={`/report/camp-donor/download/${id}?downloadPdf=true`}
              filename={'CampDonorReport'}
              title={'Camp Donor Report'}
            >
              <MenuItem sx={{ pl: 2 }}>
                <ListItemIcon>
                  <PictureAsPdf />
                </ListItemIcon>
                Camp donors PDF
              </MenuItem>
            </ReportPreview>

            <ReportPreview
              url={`/report/camp-donor/download/${id}?downloadPdf=true&isOrganizerReport=true`}
              filename={'CampDonorReport'}
              title={'Camp Donors PDF (Organizer)'}
            >
              <MenuItem sx={{ pl: 2 }}>
                <ListItemIcon>
                  <PictureAsPdf />
                </ListItemIcon>
                Camp Donors PDF (Organizer)
              </MenuItem>
            </ReportPreview>

            <MenuItem
              sx={{ pl: 2 }}
              onClick={() => {
                downLoadFiles(`/report/camp-donor/download/${id}?download=true`, 'CampDonorReport')
              }}
            >
              <ListItemIcon>
                <DocumentScanner />
              </ListItemIcon>{' '}
              Camp Donors Excel
            </MenuItem>
          </Menu>
        </div>
      </div>
      <ReactTable
        enableSorting
        data={data?.response?.length ? data?.response : []}
        columns={tableColumns}
        state={{ sorting }}
        manualSorting={true}
        onSortingChange={setSorting}
        setFilterEnable={setFilterEnable}
        heightupper={filterEnable ? 60 : 30}
      />

      {pageData && pageData.totalPage > 1 && (
        <div className='float-right mt-1'>
          <PaginationLink pagination={pageData} getNext={page => handleApplyFilter(currSearchParams, 'page', page)} />
        </div>
      )}
    </div>
  )
}
