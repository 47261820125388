import { useEffect, useState } from 'react'
import Loader from '../../shared/Loader'
import BreadCrumb from '../../common/BreadCrumb'
import ScrollCard from '../../shared/ScrollCard'
import { DonorTable } from './donor-table'
import AxiosHelper from '../../../helper/AxiosHelper'
import { useParams } from 'react-router-dom'
import { LabelDiv } from '../../screening/ScreeningDonorDetails'
import { Grid } from '@mui/material'
import { ColumnDetail } from '../../donor-inventory'
import dayjs from 'dayjs'
import { CampViewRedirect } from './camp-view-redirect'

const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/blood-camp-management',
    label: 'Blood Camp Management'
  },
  {
    label: 'Donor Registered'
  }
]

export const ViewDonorsCamp = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pageData, setPageData] = useState(null)

  const [campData, setCampData] = useState(null)
  const { id } = useParams()

  const fetchCampDetails = async id => {
    try {
      setLoading(true)
      const res = await AxiosHelper.get(`camp/${id}`)

      const { data } = res

      if (!data) throw Error("Can't fetch Camp info")

      setCampData(data?.data)
      console.log(`camp data ==> `, data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchRegDonorDetails = async id => {
    try {
      setLoading(true)
      const res = await AxiosHelper.get(`blood-search/${id}/camp-donor-list`)

      const { data } = res
      if (!data) {
        throw Error("Can't fetch Donor info!!")
      }

      setData(data?.data)
      setPageData(data?.data.pagination)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      console.log(`camp id -> `, id)
      fetchRegDonorDetails(id)
      fetchCampDetails(id)
    }
  }, [id])

  return (
    <>
      <Loader isLoading={isLoading} />
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
      <ScrollCard normal>
        <LabelDiv title={'Camp Details'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ColumnDetail
                label={'Camp Name'}
                title={
                  campData?.camp_name ? <CampViewRedirect camp_id={id}>{campData?.camp_name}</CampViewRedirect> : '-'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ColumnDetail
                label={'Schedule Date & Time'}
                title={`${
                  (campData?.camp_schedule_date ? dayjs(campData?.camp_schedule_date).format('DD-MMM-YYYY') : '-') +
                  ' & ' +
                  `${campData?.camp_start_timing} - ${campData?.camp_ending_time}`
                }`}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ColumnDetail
                label={'Inward Bags / Registered Donors'}
                title={`${campData?.inward_bag || 0} / ${campData?.registered_donor || 0}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ColumnDetail
                label={'Organizer Info'}
                title={`${campData?.organizer_name || '-'} (${campData?.organizer_mobile})`}
              />
            </Grid>
          </Grid>
        </LabelDiv>

        <DonorTable data={data} setData={setData} pageData={pageData} id={id} setLoading={setLoading} />
      </ScrollCard>
    </>
  )
}
