import React from 'react'
import { useAppContext } from '../../AppContext'

export const BloogGroupPrintRHFn = bloodGroup => {
  const bloodGroupArray = JSON.parse(localStorage.getItem('bloodGroupsArray'))
  
  if (!bloodGroup) return null
  if (bloodGroupArray && bloodGroupArray?.length === 0) return bloodGroup

  const actualPrint = bloodGroupArray?.find(item => item?.value === bloodGroup)

  if (actualPrint) {
    return actualPrint?.label
  }

  return bloodGroup
}

const BloogGroupPrintRH = ({ bloodGroup }) => {
  const { bloodGroups: bloodGroupArray } = useAppContext()

  if (!bloodGroup) return null
  if (bloodGroupArray?.length === 0) return bloodGroup

  const actualPrint = bloodGroupArray?.find(item => item?.value === bloodGroup)

  if (actualPrint) {
    return actualPrint?.label
  }

  return bloodGroup
}

export default BloogGroupPrintRH
