import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const today = new Date();

export function RHFDateTimePicker(props) {
  const { name, size, tabIndex, format, handleChange, disablePast = false, disableFuture = false, ...other } = props;
  const { control, resetField } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={{
        required: true,
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        const parsedValue = dayjs(value);

        const handleDateTimeChange = (newValue) => {
          const inputDate = dayjs(newValue);
          if ((disablePast && inputDate.isBefore(today, 'day')) || (disableFuture && inputDate.isAfter(today, 'day'))) {
            resetField(name);  // Reset field if the date doesn't meet the criteria
            onChange(null);
          } else {
            onChange(newValue);  // Format date as per your need
            if (handleChange) {
              handleChange(newValue);
            }
          }
        };

        return (
          <>
            <DateTimePicker
              value={parsedValue}
              disableFuture={disableFuture}
              disablePast={disablePast}
              inputRef={ref}
              format={format || 'DD/MM/YYYY HH:mm'}
              onChange={handleDateTimeChange}
              {...other}
              slotProps={{
                textField: (params) => ({
                  ...params,
                  tabIndex: tabIndex || 0,
                  size: 'small',
                  fullWidth: true,
                  error: Boolean(error),
                  onBlur: () => {
                    // Additional validation on blur for manual input
                    const inputDate = dayjs(value);
                    if ((disablePast && inputDate.isBefore(today, 'day')) || (disableFuture && inputDate.isAfter(today, 'day'))) {
                      resetField(name);
                      onChange(null);
                    }
                  },
                }),
              }}
            />
            {error && <FormHelperText sx={{ color: 'error.main', m: 0 }}>{error.message}</FormHelperText>}
          </>
        );
      }}
    />
  );
}
