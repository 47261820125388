import React, { useEffect, useState } from 'react'
import PageHeader from '../../common/PageHeader'
import BreadCrumb from '../../common/BreadCrumb'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  FormLabel,
  MenuItem,
  Radio,
  Checkbox,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ListItemText,
  ListItemButton,
  Autocomplete,
  Typography
} from '@mui/material'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add, Close, Info, PlusOne } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import AxiosHelper from '../../../helper/AxiosHelper'
import RHFTextField from '../../shared/RHFTextfield'
import RHFSelect from '../../shared/RHFSelect'
import { RHFDatePicker, RHFTimePicker } from '../../shared/RHFDatePicker'
import FormProvider from '../../shared/FormProvider'
import { bloodComponent } from '../../donor-inventory/data'
import Loader from '../../shared/Loader'
import dayjs from 'dayjs'
import { checkPermission, getPinCodeData } from '../../middleware/auth'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import { RHFAutoComplete } from '../../shared/RHFAutoComplete'
import { phoneRegExp } from '../../abha/components/DonorDetail'
import { useAppContext } from '../../../AppContext'

const FormDivider = ({ title }) => {
  return (
    <Grid className='Donor-details-sections' item xs={12}>
      <div className='detail-header'>{title ? title : ''}</div>
    </Grid>
  )
}

export const radioStyles = {
  '&.MuiCheckbox-indeterminate,&.Mui-checked': {
    color: '#214F8A'
  }
}

let LetterMessage = 'Must be less than 50 characters'

// Define the regular expression for email validation
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const letters = /^[a-zA-Z ]*$/
const defaultValues = {
  special_dis: true,
  organizer_type: '',
  organizer_designation: '',
  state: '',
  district: '',
  organization_name: '',
  camp_purpose: '',
  organization_id: '',
  camp_type: 'self',
  organized_by: 'organization'
}

const schema = yup.object().shape({
  camp_type: yup.string().required('This field is required'),
  organization_name: yup.string().when('organized_by', {
    is: 'organization',
    then: () => yup.string().required('Organization name is required')
  }),
  organizer_name: yup
    .string()
    .required('Organizer name is required')
    .max(50, LetterMessage)
    .matches(letters, 'Organizer name is not valid'),
  new_organization_name: yup.string().max(50, LetterMessage),
  organizer_designation: yup.string().when('organized_by', {
    is: 'organization',
    then: () => yup.string().required('Designation is required')
  }),
  organizer_mobile: yup
    .string()
    .required('Mobile number is required')
    .matches(phoneRegExp, 'mobile number is not valid'),
  // organizer_email: yup
  //   .string()
  //   .matches(emailRegex, "Email is not valid"),
  // organizer_dob: yup.string(),
  organizer_type: yup.string().when('organized_by', {
    is: 'organization',
    then: () => yup.string().required('Organization Type is required')
  }),
  camp_name: yup.string().required('Camp name is required').max(50, LetterMessage),
  camp_schedule_date: yup.string().required('Date is required'),

  camp_ending_time: yup.string().required('Time is required'),
  camp_start_timing: yup.string().required('Time is required'),
  camp_purpose: yup.string().required('Purpose of Camp is required'),
  address: yup.string().required('Address is required').max(50, LetterMessage),
  city: yup.string().required('City is required').max(50, LetterMessage),
  district: yup.string().required('District is required'),
  state: yup.string().required('State is required'),
  pincode: yup.string().max(6, 'Invalied pin code'),
  pro_name: yup
    .string()
    .required('PRO Name is required')
    .max(50, LetterMessage)
    .matches(letters, 'Organizer name is not valid'),
  pro_contact: yup.string().required('PRO number is required').matches(phoneRegExp, 'Mobile number is not valid'),
  // expected_donors: yup.string(),
  expected_unit_collection: yup.string().required('Tentative blood unit collection quantity is required'),
  additional_info: yup.string().max(300, LetterMessage)
})

export const inputMultiStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },

  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

// export const inputStyles = {
//   background: "#f5f5f5",
//   border: "1px solid #d1d5db",
//   borderRadius: "0.5rem",
//   "& .MuiOutlinedInput-notchedOutline": {
//     borderWidth: 0,
//   },
//   "& .MuiInputBase-input": {
//     height: 30,
//   },
//   "& .MuiInputBase-root": {
//     height: 42,
//   },
//   "&:focus-within fieldset, &:focus-visible fieldset": {
//     borderWidth: "1px!important",
//     borderRadius: "0.5rem",
//   },
// };

export const designations = [
  'Director',
  'Chairman',
  'Managing Director,Chief Executive Officer',
  'Chief',
  'Financial Officer',
  'Secretary',
  'Chief Operating Officer',
  'Chief Technology Officer',
  'Vice President',
  'Manager',
  'Other'
]

export const organisationType = [
  'Corporate',
  'Educational Institute',
  'Event Camp',
  'Hospital Based',
  'Trust',
  'Government',
  'Others'
]

export const validation = {
  shouldValidate: true,
  shouldDirty: true
}

const AddBloodCamp = ({ handleCancel,isEditable }) => {
  const [isLoading, setLoading] = useState(false)
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [info, setInfo] = useState({})
  const [organization, setOrganization] = useState({ name: '' })
  const [o_designations, setO_Desiganation] = useState('')
  const [bloodBankInfo, setBloodBankInfo] = useState([])
  const [newOrganizationError, setnewOrganizationError] = useState(false)
  const [organizationNames, setOrgnizationNames] = useState([])
  const [newOrganization, setNewOrganization] = useState(false)
  const [edit, setEdit] = useState(false)
  const { id } = useParams()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const history = useHistory()

  const { setActiveTutorial } = useAppContext()
  
  useEffect(() => {
    setActiveTutorial('manageCamp')

    return () => {
      setActiveTutorial('')
    }
  }, [])

  // ** Hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors }
  } = methods

  console.log('errors', errors)

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const getStates = () => {
    AxiosHelper.get('address/state')
      .then(response => {
        setStates(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleOrganization = e => {
    console.log(e.target.value)
    if (e.target.value === 'Others') {
      setNewOrganization(true)
      setValue('organization_id', e.target.value)
    } else {
      setNewOrganization(false)
      setValue('organization_id', e.target.value)
    }
  }

  const getDistict = val => {
    AxiosHelper.get('address/district/' + val)
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const BloodBankData = () => {
    setLoading(true)

    AxiosHelper.get(`tenants/setting`)
      .then(resp => {
        setBloodBankInfo(resp.data.data)
        setLoading(false)
        console.log(resp.data.data)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (watch('camp_type') === 'self' && bloodBankInfo) {
      setOrganization({ name: bloodBankInfo?.name })
      setValue('organization_name', bloodBankInfo?.name)
      setValue('organization_id', bloodBankInfo?.name)
      setValue('organizer_mobile', bloodBankInfo?.contact_number)
      setValue('organizer_email', bloodBankInfo?.email)
      setValue('organizer_name', bloodBankInfo?.contact_person)
      setValue('organizer_type', 'Others')
    } else {
      if (!edit) {
        setOrganization({ name: '' })
        setValue('organization_id', '')
        setValue('organizer_mobile', '')
        setValue('organizer_email', '')
        setValue('organizer_name', '')
        setValue('organizer_type', '')
      } else {
        setOrganization(info?.OrganizationEntity)
        setO_Desiganation(info?.organizer_designation)
        setValue('organization_id', `${info?.organization_id}` || '')
        setValue('organizer_mobile', info?.organizer_mobile || '')
        setValue('organizer_email', info?.organizer_email || '')
        setValue('organizer_name', info?.organizer_name || '')
        setValue('organizer_type', info?.organizer_type || '')
      }
    }
  }, [watch('camp_type'), bloodBankInfo])

  const handleRadio = e => {
    setValue('camp_type', e.target.value)
  }

  const getOrganiser = () => {
    AxiosHelper.get('camp/organizations')
      .then(response => {
        // let orgdata = response?.data?.data
        //  let orglist = [...orgdata, { id: "Others", name: "Other" }];
        setOrgnizationNames(response?.data?.data)
        console.log(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleBloodCamp = (url, finaldata) => {
    let bodyData = { ...finaldata }
    if (id) {
      setLoading(true)
      AxiosHelper.put(`camp/${id}`, bodyData)
        .then(response => {
          toast.success('Camp Details Sucessfully Edited')
          setLoading(false)
          handleCancel()
        })
        .catch(error => {
          console.log(error)
          toast.error('Fail To Edit Blood Camp!')
          handleCancel()
        })
      setLoading(false)
    } else {
      setLoading(true)
      AxiosHelper.post(url, bodyData)
        .then(response => {
          toast.success('Blood Camp Successfully Added!')
          console.log(response.data.data)
          setLoading(false)
          history.push('/blood-camp-management')
        })
        .catch(error => {
          console.log(error)
          toast.error(error)
        })
      setLoading(false)
    }
  }

  useEffect(() => {
    getStates()
    BloodBankData()
    getOrganiser()
  }, [])

  useEffect(() => {
    if (watch('state')) {
      getDistict(watch('state'))
    }
  }, [watch('state'), states])

  async function getAddressVal(val) {
    if (val?.length === 6) {
      setLoading(true)
      let address = await getPinCodeData(val)
      if (address) {
        setValue('state', address?.State || '')
        setValue('district', address?.District || '')
        setValue('city', address?.Block || '')
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getAddressVal(watch('pincode'))
  }, [watch('pincode')])

  const getCampDetails = () => {
    setLoading(true)
    AxiosHelper.get(`camp/${id}`)
      .then(response => {
        let editdata = response?.data?.data
        setInfo(response.data.data)
        setOrganization(editdata?.OrganizationEntity)
        setO_Desiganation(editdata?.organizer_designation)
        setEdit(true)
        reset({
          id: editdata?.id,
          organization_name: editdata?.OrganizationEntity?.name || '',
          organization_id: editdata?.organization_id || '',
          camp_type: editdata?.camp_type || '',
          organizer_name: editdata?.organizer_name || '',
          special_dis: editdata?.special_dis,
          organizer_designation: editdata?.organizer_designation || '',
          organizer_mobile: editdata?.organizer_mobile || '',
          organizer_email: editdata?.organizer_email || '',
          organizer_dob: dayjs(editdata?.organizer_dob) || '',
          organizer_type: editdata?.organizer_type || '',
          camp_name: editdata?.camp_name || '',
          camp_schedule_date: dayjs(editdata?.camp_schedule_date) || '',
          camp_start_timing: editdata?.camp_start_timing || '',
          camp_ending_time: editdata?.camp_ending_time || '',
          address: editdata?.address || '',
          district: editdata?.district || '',
          state: editdata?.state || '',
          city: editdata?.city || '',
          pincode: editdata?.pincode || '',
          pro_name: editdata?.pro_name || '',
          pro_contact: editdata?.pro_contact || '',
          expected_donors: editdata?.expected_donors || '',
          expected_unit_collection: editdata?.expected_unit_collection || '',
          camp_purpose: editdata?.camp_purpose || '',
          distance: editdata?.distance || '',
          additional_info: editdata?.additional_info || '',
          remark: editdata?.remark || ''
        })
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  const handleSpecial = e => {
    console.log(e.target.checked)
    if (e.target.checked) {
      setValue('special_dis', true)
    } else {
      setValue('special_dis', false)
    }
  }

  useEffect(() => {
    if (id) {
      getCampDetails()
    }
  }, [id])

  const onSubmit = data => {
    if (newOrganization) {
      if (data?.new_organization_name?.length > 0) {
        handleBloodCamp('camp', data)
      } else {
        setnewOrganizationError(true)
      }
    } else {
      handleBloodCamp('camp', data)
    }
  }

  console.log('camp_start_timing', watch('camp_start_timing'), watch('camp_ending_time'))

  return (
    <>
      <Loader isLoading={isLoading} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className='bloodbank_action  '>{edit ? <p>Edit Blood Camp</p> : <p>Add Blood Camp</p>}</div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormLabel sx={labelStyles} id='demo-row-radio-buttons-group-label'>
              Camp Organised For<span className='text-danger'>*</span>
            </FormLabel>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Radio
                inputProps={{
                  // tabIndex: "1",
                  'aria-label': 'Self'
                }}
                sx={radioStyles}
                checked={watch('camp_type') === 'self'}
                onChange={handleRadio}
                value='self'
                name='camp_type'
              />
              <FormLabel sx={{ ...labelStyles, mr: '50px' }} id='demo-row-radio-buttons-group-label'>
                In-house
              </FormLabel>

              <Radio
                inputProps={{
                  // tabIndex: "2",
                  'aria-label': 'Other'
                }}
                sx={radioStyles}
                checked={watch('camp_type') === 'other'}
                onChange={handleRadio}
                value='other'
                name='camp_type'
              />
              <FormLabel sx={labelStyles} id='demo-row-radio-buttons-group-label'>
                With Collaboration
              </FormLabel>
            </Grid>
            {errors.camp_type ? (
              <FormHelperText sx={{ color: 'error.main' }}>{errors.camp_type?.message}</FormHelperText>
            ) : null}
          </Grid>

          <FormDivider title='Organizer Details' />

          {watch('camp_type') === 'other' ? (
            <Grid item xs={12}>
              <FormLabel sx={labelStyles} id='demo-row-radio-buttons-group-label'>
                Camp Organised By<span className='text-danger'>*</span>
              </FormLabel>
              <RadioGroup
                row
                value={watch('organized_by')}
                onChange={e => {
                  setValue('organized_by', e.target.value)
                  setOrganization({ name: '' })
                  if (e.target.value === 'individual') {
                    setValue('organization_name', '')
                    setValue('organizer_name', 'VVD Camp')
                    setValue('organization_id', 'Others')
                    setValue('organizer_type', 'Others')
                    setValue('organizer_designation', 'Other')
                  } else {
                    setValue('organization_id', '')
                    setValue('organizer_type', '')
                  }
                }}
              >
                <FormControlLabel
                  value='organization'
                  control={<Radio sx={radioStyles} />}
                  label={<Typography sx={labelStyles}>Organization</Typography>}
                />
                <FormControlLabel
                  value='individual'
                  control={<Radio sx={radioStyles} />}
                  label={<Typography sx={labelStyles}>Individual</Typography>}
                />
              </RadioGroup>
            </Grid>
          ) : null}
          {watch('organized_by') === 'organization' ? (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                Organization Name<span className='text-danger'>*</span>
              </FormLabel>
              <Autocomplete
                freeSolo
                // inputProps={{
                //   tabIndex: "3",
                // }}
                autoHighlight
                size='small'
                disableClearable={true}
                options={organizationNames}
                name='organization_name'
                forcePopupIcon
                // isOptionEqualToValue={() => true}
                getOptionLabel={option => (option ? option.name : '')}
                value={organization}
                disabled={watch('camp_type') === 'self' ? true : false}
                onChange={(event, obj) => {
                  setOrganization(obj)
                  setValue('organization_id', `${obj.id}`)
                  setValue('organization_name', obj.id)
                }}
                onInputChange={(event, val, reason) => {
                  if (reason === 'reset') return
                  setOrganization({ name: val })
                  setValue('organization_id', 'Others')
                  setValue('new_organization_name', val)
                  setValue('organization_name', val)
                }}
                renderInput={params => {
                  return <TextField {...params} sx={inputStyles} />
                }}
              />
              {errors.organization_name ? (
                <FormHelperText sx={{ color: 'error.main' }}>{errors.organization_name?.message}</FormHelperText>
              ) : null}
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Organizer Name <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "4",
              // }}
              name='organizer_name'
              size='small'
              fullWidth
              sx={inputStyles}
              // onChange={handleId}
              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div className='d-flex mt-4' style={{ position: 'relative', right: '20px' }}>
              <Checkbox
                // inputProps={{
                //   tabIndex: "5",
                // }}
                sx={radioStyles}
                name='special_dis'
                checked={watch('special_dis') === true}
                onChange={handleSpecial}
              />
              <FormLabel sx={{ ...labelStyles, mt: '12px' }}>SP. Discount Authority</FormLabel>
            </div>
          </Grid>
          {watch('organized_by') === 'organization' ? (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                Organizer Designation <span className='text-danger'>*</span>
              </FormLabel>

              <RHFAutoComplete
                freeSolo
                autoHighlight
                autoComplete
                size='small'
                disableClearable={true}
                options={designations}
                name='organizer_designation'
                forcePopupIcon
                // isOptionEqualToValue={() => true}
                value={o_designations}
                handleChange={obj => {
                  setO_Desiganation(obj)
                  setValue('organizer_designation', obj, validation)
                }}
                onInputChange={(event, val, reason) => {
                  if (reason === 'reset') return
                  setO_Desiganation(val)
                  setValue('organizer_designation', val, validation)
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Organizer Mobile
              <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "7",
              // }}
              name='organizer_mobile'
              type='number'
              size='small'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <Select size='small' variant='standard' disableUnderline value='+91'>
                      <MenuItem value='+91'>+91</MenuItem>
                    </Select>
                  </Box>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Organizer Email</FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "8",
              // }}
              name='organizer_email'
              type='email'
              size='small'
              fullWidth
              sx={inputStyles}

              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Organizer DOB</FormLabel>
            <RHFDatePicker name='organizer_dob' disableFuture sx={inputStyles} />
          </Grid>
          {watch('organized_by') === 'organization' ? (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                Organization Type
                <span className='text-danger'>*</span>
              </FormLabel>
              <RHFSelect
                size='small'
                // inputProps={{
                //   tabIndex: "10",
                // }}
                name='organizer_type'
                fullWidth
                sx={inputStyles}

                // InputProps={InputEndProps}
              >
                {organisationType?.length > 0 ? (
                  organisationType?.map(tp => <MenuItem value={tp}>{tp}</MenuItem>)
                ) : (
                  <MenuItem value='Others'>Others</MenuItem>
                )}
              </RHFSelect>
            </Grid>
          ) : null}

          <FormDivider title='Camp Details' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Camp Name<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "11",
              // }}
              name='camp_name'
              size='small'
              fullWidth
              sx={inputStyles}
              // onChange={handleId}
              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Camp Schedule Date<span className='text-danger'>*</span>
            </FormLabel>
            <RHFDatePicker
              // inputProps={{
              //   tabIndex: "12",
              // }}
              name='camp_schedule_date'
              // disablePast commenting as asked by sandeeep sir on urgent 30/12/24
              sx={inputStyles}
              disabled={isEditable}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Schedule Time From <span style={{ fontSize: '12px' }}>(24hr format)</span>
              <span className='text-danger'>*</span>
            </FormLabel>
            {/* 
            <TimePicker 
            ampm={false}
            onChange={(e,newValue,)=>{
              console.log('newValue',e.target.value,newValue);
            }}
            label="Basic time picker" /> */}

            <RHFTimePicker size='small' disabled={isEditable} fullWidth sx={inputStyles} name='camp_start_timing' />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Schedule Time To <span style={{ fontSize: '12px' }}>(24hr format)</span>
              <span className='text-danger'>*</span>
            </FormLabel>

            <RHFTimePicker
              minTime={dayjs(watch('camp_start_timing'), 'HH:mm')}
              size='small'
              fullWidth
              sx={inputStyles}
              disabled={isEditable}
              name='camp_ending_time'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Purpose of Camp<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect
              size='small'
              // inputProps={{
              //   tabIndex: "15",
              // }}
              name='camp_purpose'
              fullWidth
              sx={inputStyles}

              // InputProps={InputEndProps}
            >
              <MenuItem value='Social awareness to support voluntary blood donation'>
                Social awareness to support voluntary blood donation
              </MenuItem>
              <MenuItem value='Special_Occasion_Birthday'>Special Occasion - Birthday</MenuItem>
              <MenuItem value='Special_Occasion'>Occasion - Memorial</MenuItem>
              <MenuItem value='Others'>Other</MenuItem>
            </RHFSelect>
          </Grid>

          <FormDivider title='Camp Venue' />
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Address<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              size='small'
              // inputProps={{
              //   tabIndex: "16",
              // }}
              name='address'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              State<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect
              size='small'
              // inputProps={{
              //   tabIndex: "17",
              // }}
              name='state'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
            >
              {states?.length > 0 ? (
                states?.map((stat, i) => (
                  <MenuItem key={i} value={stat?.name}>
                    {stat?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No States found</MenuItem>
              )}
            </RHFSelect>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              District<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect
              // inputProps={{
              //   tabIndex: "18",
              // }}
              size='small'
              name='district'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
            >
              {districts?.length > 0 ? (
                districts?.map((stat, i) => (
                  <MenuItem key={i} value={stat?.name}>
                    {stat?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No District found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              City/Village<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              size='small'
              fullWidth
              // inputProps={{
              //   tabIndex: "19",
              // }}
              name='city'
              sx={inputStyles}
              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Pin Code</FormLabel>
            <RHFTextField
              type='number'
              size='small'
              // inputProps={{
              //   tabIndex: "20",
              // }}
              name='pincode'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
            />
            <FormHelperText>Enter a valid Pin code to get state & district</FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>Distance From Blood Centre(km)</FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "21",
              // }}
              name='distance'
              type='number'
              size='small'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
            />
          </Grid>

          <FormDivider title='Other Details' />

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Public Relations Officer Name
              <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              size='small'
              // inputProps={{
              //   tabIndex: "22",
              // }}
              fullWidth
              name='pro_name'
              sx={inputStyles}
              // InputProps={InputEndProps}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              PRO Contact Number
              <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              size='small'
              // inputProps={{
              //   tabIndex: "23",
              // }}
              fullWidth
              type='number'
              name='pro_contact'
              sx={inputStyles}
              // InputProps={InputEndProps}
              InputProps={{
                startAdornment: (
                  <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                    <Select size='small' variant='standard' disableUnderline value='+91'>
                      <MenuItem value='+91'>+91</MenuItem>
                    </Select>
                  </Box>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormLabel sx={labelStyles}>
              Tentative Blood Unit Collection Quantity
              <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "24",
              // }}
              name='expected_unit_collection'
              type='number'
              size='small'
              fullWidth
              sx={inputStyles}
              // InputProps={InputEndProps}
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel sx={labelStyles}>Remark</FormLabel>
            <RHFTextField
              // inputProps={{
              //   tabIndex: "25",
              // }}
              size='small'
              multiline
              minRows={2}
              maxRows={5}
              fullWidth
              sx={inputMultiStyles}
              name='remark'
              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12}>
            <div className='d-flex pb-3' style={{ gap: '10px' }}>
              {edit ? (
                <>
                  <button
                    type='button'
                    className='btn backBtn '
                    onClick={() => {
                      handleCancel()
                      // history.push(`/blood-camp-management/view/${id}`);
                    }}
                  >
                    Cancel
                  </button>
                  {checkPermission('ADD_CAMP') || checkPermission('UPDATE_CAMP') ? (
                    <button type='save' disabled={isLoading} className='btn gradient-btn'>
                      {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                    </button>
                  ) : null}
                </>
              ) : (
                <>
                  <button
                    type='button'
                    className='btn backBtn '
                    onClick={() => {
                      history.push('/blood-camp-management')
                    }}
                  >
                    Cancel
                  </button>
                  {checkPermission('ADD_CAMP') || checkPermission('UPDATE_CAMP') ? (
                    <button type='submit' disabled={isLoading} className='btn gradient-btn'>
                      {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                    </button>
                  ) : null}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}

export default AddBloodCamp
