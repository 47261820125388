import { useHistory } from 'react-router-dom'
import { CardHeader } from '../shared/card-header'
import { InfoItem } from '../shared/info-item'

const getStageDetail = data => {
  if (data?.stage_three_group) {
    return { group: data?.stage_three_group, detail: data?.stage_three_detail, stage: 'Stage Three' }
  } else if (data?.stage_two_group) {
    return { group: data?.stage_two_group, detail: data?.stage_two_detail, stage: 'Stage Two' }
  } else {
    return {
      group: data?.stage_one_group,
      detail: JSON.stringify(BloodGroupValues(data?.stage_one_group, data?.stage_one_detail).group_antigens),
      stage: 'Stage One'
    }
  }
}

export const BloodGroupValues = (selectedBloodGroup, data) => {
  // setSelectedGroup(selectedBloodGroup)
  let determinedgroup = ''
  let group_antigens = {
    ...data,
    anti_d: '',
    anti_b: '',
    anti_ab: '',
    anti_a: '',
    anti_ai: '',
    pt_cells: '',
    o_cells: '',
    b_cells: '',
    a_cells: ''
  }
  if (selectedBloodGroup === 'A+Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '3',
      anti_b: '0',
      anti_ab: '3',
      anti_a: '3',
      anti_ai: '3',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '3',
      a_cells: '0'
    }
    determinedgroup = 'A+Ve'
  }

  if (selectedBloodGroup === 'A-Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '0',
      anti_b: '0',
      anti_ab: '3',
      anti_a: '3',
      anti_ai: '3',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '3',
      a_cells: '0'
    }
    determinedgroup = 'A-Ve'
  }

  if (selectedBloodGroup === 'B+Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '3',
      anti_b: '3',
      anti_ab: '3',
      anti_a: '0',
      anti_ai: '0',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '0',
      a_cells: '3'
    }
    determinedgroup = 'B+Ve'
  }

  if (selectedBloodGroup === 'B-Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '0',
      anti_b: '3',
      anti_ab: '3',
      anti_a: '0',
      anti_ai: '0',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '0',
      a_cells: '3'
    }
    determinedgroup = 'B-Ve'
  }

  if (selectedBloodGroup === 'O+Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '3',
      anti_b: '0',
      anti_ab: '0',
      anti_a: '0',
      anti_ai: '0',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '3',
      a_cells: '3'
    }
    determinedgroup = 'O+Ve'
  }

  if (selectedBloodGroup === 'O-Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '0',
      anti_b: '0',
      anti_ab: '0',
      anti_a: '0',
      anti_ai: '0',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '3',
      a_cells: '3'
    }
    determinedgroup = 'O-Ve'
  }

  if (selectedBloodGroup === 'AB+Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '3',
      anti_b: '3',
      anti_ab: '3',
      anti_a: '3',
      anti_ai: '0',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '0',
      a_cells: '0'
    }
    determinedgroup = 'AB+Ve'
  }

  if (selectedBloodGroup === 'AB-Ve') {
    group_antigens = {
      ...group_antigens,
      anti_d: '0',
      anti_b: '3',
      anti_ab: '3',
      anti_a: '3',
      anti_ai: '0',
      pt_cells: '0',
      o_cells: '0',
      b_cells: '0',
      a_cells: '0'
    }
    determinedgroup = 'AB-Ve'
  }
  // setData({ ...group_antigens })
  // setBGroup(determinedgroup)

  return { determinedgroup, group_antigens }
}

export const GroupingInfo = ({ trailData, BloodBagStageGroupingEntity, data }) => {
  const history = useHistory()
  const actionCallBack = async e => {
    console.log(`edit grouping`, data)
    const bgnumber = data?.bag_number || data?.BloodBagIssueEntity?.bag_number
    if (bgnumber) history.push(`/serology/blood-group-testing?bgnumber=${bgnumber}`)
  }

  console.log(`stage data -> `, getStageDetail(BloodBagStageGroupingEntity).detail)

  return (
    <>
      <CardHeader
        deleteBtn={false}
        actionBtns
        actionCallBack={actionCallBack}
        lastUpdated={BloodBagStageGroupingEntity?.updatedAt}
      >
        Grouping{' '}
        <span className='trail'>
          ({getStageDetail(BloodBagStageGroupingEntity)?.stage}: {getStageDetail(BloodBagStageGroupingEntity)?.group})
        </span>
      </CardHeader>
      <div>
        <div>
          <div className='donation-history-status-header mt-2 ' style={{ fontSize: '14px' }}>
            <span
              style={{
                fontWeight: 'bold',
                color: '#6B7280'
              }}
            >
              Forward Grouping
            </span>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: '1rem',
              padding: '0.5rem'
            }}
          >
            <InfoItem title='Anti-A'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.anti_a || '-'}
            </InfoItem>
            <InfoItem title='Anti-AI'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.anti_ai || '-'}
            </InfoItem>
            <InfoItem title='Anti-B'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.anti_b || '-'}
            </InfoItem>
            <InfoItem title='Anti-AB'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.anti_ab || '-'}
            </InfoItem>
            <InfoItem title='Anti-D(RHO)'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.anti_d || '-'}
            </InfoItem>
          </div>
        </div>
        <div>
          <div className='donation-history-status-header mt-2 ' style={{ fontSize: '14px' }}>
            <span
              style={{
                fontWeight: 'bold',
                color: '#6B7280'
              }}
            >
              Backward Grouping
            </span>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gap: '1rem',
              padding: '0.5rem'
            }}
          >
            <InfoItem title='A Cells'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.a_cells || '-'}
            </InfoItem>
            <InfoItem title='B Cells'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.b_cells || '-'}
            </InfoItem>
            <InfoItem title='O Cells'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.o_cells || '-'}
            </InfoItem>
            <InfoItem title='PT Cells'>
              {JSON.parse(getStageDetail(BloodBagStageGroupingEntity).detail)?.pt_cells || '-'}
            </InfoItem>
          </div>
        </div>
      </div>
    </>
  )
}
