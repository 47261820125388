export const StatusCapsule = ({ children, status, style, onClick }) => {
  const getStatusStyles = () => {
    switch (status) {
      case 'done':
        return { color: '#1a7f37', backgroundColor: '#e6f4ea' } // green text, lighter green background
      case 'pending':
        return { color: '#9a6700', backgroundColor: '#fff7e0' } // dark yellow/brown text, lighter yellow background
      case 'cancel':
        return { color: '#a61d24', backgroundColor: '#fdecea' } // dark red text, lighter red background
      case 'primary':
        return { color: '#0b5394', backgroundColor: '#e0f0ff' } // blue text, lighter blue background
      default:
        return {}
    }
  }

  return (
    <div
      onClick={onClick}
      style={{
        padding: '4px 8px',
        borderRadius: '8px',
        fontWeight: 'bold',
        width: 'max-content',
        ...getStatusStyles(),
        ...style
      }}
    >
      {children}
    </div>
  )
}
