import moment from 'moment'
import React from 'react'

const TableRow = ({ bag, donorHistory }) => {
  return (
    <tr>
      <td valign='top'>{bag?.assigned_date ? moment(bag?.assigned_date).format('DD-MMM-YYYY HH:mm') :
        bag?.screening_date ? moment(bag?.screening_date).format('DD-MMM-YYYY HH:mm') :
          bag?.BloodTestingEntity?.testing_date ? moment(bag?.BloodTestingEntity?.testing_date).format('DD-MMM-YYYY HH:mm') :
            "-"}</td>
      <td>{donorHistory?.reg_id}</td>
      <td>
        {bag?.bag_type}
        <div className='mt-1'>{bag?.bag_id}</div>
      </td>
      {/* <td>{bag?.bag_id}</td> */}
      <td>{bag?.tube_number}</td>
      <td>
        {bag &&
          bag.BloodLogsEntities &&
          bag.BloodLogsEntities.map((row, index) => (
            <p key={index} className='chip_custom'>
              {row?.component}
            </p>
          ))}
      </td>
      <td>
        {bag &&
          bag.BloodLogsEntities &&
          bag.BloodLogsEntities.map((row, index) => (
            <p className='chip_custom_text' key={index}>
              {row?.volume}
            </p>
          ))}
      </td>
      <td>
        {bag &&
          bag.BloodLogsEntities &&
          bag.BloodLogsEntities.map((row, index) => (
            <p className='chip_custom_text' key={index}>
              1
            </p>
          ))}
      </td>
      <td>
        {bag &&
          bag.BloodLogsEntities &&
          bag.BloodLogsEntities.map((row, index) => (
            <p className='chip_custom_text' key={index}>
              {row?.expiry_days}
            </p>
          ))}
      </td>
      <td>
        {bag &&
          bag.BloodLogsEntities &&
          bag.BloodLogsEntities.map((row, index) => (
            <p className='chip_custom_text' key={index}>
              {moment(row?.expiry_date || new Date()).format('DD-MMM-YYYY')}
            </p>
          ))}
      </td>
    </tr>
  )
}

export default TableRow
