import React, { useState, useEffect } from 'react'
import PageHeader from '../common/PageHeader'
import AxiosHelper from '../../helper/AxiosHelper'
import BreadCrumb from '../common/BreadCrumb'
import Loader from '../shared/Loader'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SyncIcon from '@mui/icons-material/Sync'

import {
  Alert,
  Box,
  Card,
  Checkbox,
  Collapse,
  FormLabel,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import LoadingButton from '../shared/LoadingButton'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import ScrollCard from '../shared/ScrollCard'
import ScrollBar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { active } from '../blood-requisition/Filters'
import dayjs from 'dayjs'
import ModalCustom, { ModalActions } from '../shared/ModalCustom'
import StockReport from '../print-components/StockReport'
import DailyReport from '../print-components/DailyReport'
import { checkTenantId } from '../../helper/CommonAPI'
import { FiltersDonor } from '../donors/components/FiltersDonor'

const bloodGroup = {
  'AB-Ve': 'AB-',
  'AB+Ve': 'AB+',
  'A+Ve': 'A+',
  'A-Ve': 'A-',
  'B+Ve': 'B+',
  'B-Ve': 'B-',
  'O-Ve': 'O-',
  'O+Ve': 'O+',
  'OH-Ve': 'OH-',
  'OH+Ve': 'OH+',
  'A2B+Ve': 'A2B+',
  'A2B-Ve': 'A2B-'
}
// /blood-stock?stocktype=camp&search=whole&expiry=2&inventorytype=all
const initialFil = {
  stocktype: '',
  search: '',
  expiry: '',
  inventorytype: '',
  search_main: ''
}

const BloodComponent = () => {
  const [allData, setAllData] = useState([])
  const [data, setData] = useState([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [columns, setColumns] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [filters, setFilters] = useState(initialFil)
  const [syncDone, setSyncDone] = useState(false)
  const [lastsync, setLastSync] = useState(null)
  const [syncLoading, setSyncLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const history = useHistory()

  const handleFilterChange = e => {
    let checkValues = {
      ...filters,
      [e.target.name]: e.target.value
    }
    setFilters(checkValues)
    if (e.target.name === 'search_main') {
      handleApply(checkValues, false)
    }
  }
  const handleInvTypeChange = val => {
    let checkValues = {
      ...filters,
      inventorytype: val
    }
    setFilters(checkValues)
    handleApply(checkValues, false)
  }

  const getBloodStock = filters => {
    setLoading(true)
    let url = checkTenantId('blood-stock')
    AxiosHelper.get(url)
      .then(resp => {
        setAllData(resp.data.data)
        setData(resp.data.data?.bloodStock)
        setColumns(Object.keys(resp.data.data?.bloodStock?.[0]))
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }
  const getLastSync = () => {
    setLoading(true)
    AxiosHelper.get('report/getlastsync?sync_type=2')
      .then(resp => {
        setLastSync(resp.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getBloodStock()
    getLastSync()
  }, [])

  // console.log('col', columns, data)

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    },
    {
      path: '/blood-stock',
      label: 'Blood Stock'
    }
  ]

  const openMenu = event => {
    setAnchor(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  function handleApply(dataa, type) {
    if (dataa?.search || dataa?.stocktype || dataa?.search_main || dataa?.inventorytype || dataa?.expiry) {
      let url = `blood-stock?stocktype=${dataa?.stocktype}&search=${
        dataa?.search || dataa?.search_main || ''
      }&expiry=${dataa?.expiry}${dataa?.inventorytype && `&inventorytype=${dataa?.inventorytype}`}`
      getData(url, type)
    } else {
      getData('blood-stock', type)
    }
  }

  const getData = (url, type) => {
    setBtnLoading(true)
    const URL = checkTenantId(url)
    AxiosHelper.get(URL)
      .then(resp => {
        setAllData(resp.data.data)
        setData(resp.data.data?.bloodStock)
        setColumns(Object.keys(resp.data.data?.bloodStock?.[0]))
        setBtnLoading(false)
        setAnchor(null)
        if (type !== false) {
          setIsFiltered(true)
        }
      })
      .catch(error => {
        setBtnLoading(false)
        console.log(error)
      })
  }

  function resetFilter() {
    setFilters(initialFil)
    handleApply(initialFil, false)
    setIsFiltered(false)
  }

  function removeFilters(name) {
    let checkVal = {
      ...filters,
      [name]: ''
    }
    setFilters(checkVal)
    handleApply(checkVal)
  }

  const handleSync = () => {
    setSyncLoading(true)
    AxiosHelper.get('report/syncstock', {})
      .then(response => {
        setSyncLoading(false)
        setLastSync(response?.data?.data?.last_sync)
        setSyncDone(true)
        setOpenConfirm(false)
        setTimeout(() => {
          setSyncDone(false)
        }, 5000)
      })
      .catch(error => {
        setSyncLoading(false)
        toast.error(error)
        console.log(error)
      })
  }

  if (isLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />

      <ScrollCard>
        <div className='search-and-filter-container align-items-center d-flex justify-content-between'>
          <div className='search-container add-dnr-search'>
            <input
              name='search_main'
              value={filters?.search_main}
              onChange={handleFilterChange}
              className='search-input'
              type='text'
              placeholder='Search'
            />
            <img
              src={'../../assets/images/setting-5.svg'}
              id='basic-button'
              // onClick={() => {
              //   setFilterbtn(!filterbtn);
              // }}
              onClick={openMenu}
              alt='search-icon'
            />
          </div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <StockReport
              baseUrl={'master-register/component-register'}
              pdf={true}
              excel={true}
              type={'stock'}
              name='Component Register'
            />
            <DailyReport
              baseUrl={'report/daily-stock-report'}
              pdf={true}
              excel={false}
              type={'Daily_stock_report'}
              name={'Daily Stock'}
            />
          </div>
        </div>
        <Menu
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={closeMenu}
          keepMounted
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}

          //TransitionComponent={Slide}
        >
          {' '}
          <div className='fltr-container' style={{ width: '33rem', height: '250px' }}>
            <div className='row mx-widthh'>
              <div className='fltrlabel-div'>
                <FormLabel sx={labelStyles}>Source of Stock</FormLabel>
              </div>
              <div className='col-md-8'>
                <Select
                  sx={inputStyles}
                  fullWidth
                  select
                  placeholder='Enter Stock Type'
                  name='stocktype'
                  size='small'
                  value={filters?.stocktype}
                  onChange={handleFilterChange}
                  displayEmpty
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='loanbank'>Loan Bank</MenuItem>
                  <MenuItem value='bloodbank'>Blood Bank</MenuItem>
                  <MenuItem value='camp'>Camp</MenuItem>
                </Select>
              </div>
            </div>
            <div className='row mx-widthh'>
              <div className='fltrlabel-div'>
                <FormLabel sx={labelStyles}>Stock Expiry</FormLabel>
              </div>
              <div className='col-md-8'>
                <Select
                  sx={inputStyles}
                  fullWidth
                  select
                  size='small'
                  placeholder='Enter Stock Expiry'
                  inputProps={{
                    placeholder: 'Select Expiry'
                  }}
                  name='expiry'
                  value={filters?.expiry}
                  onChange={handleFilterChange}
                >
                  <MenuItem value='2'>In 2 Days</MenuItem>
                  <MenuItem value='3'>In 3 Days</MenuItem>
                  <MenuItem value='5'>In 5 Days</MenuItem>
                  <MenuItem value='9'>In 9 Days</MenuItem>
                </Select>
              </div>
            </div>

            <div className='row '>
              <div className='fltraction'>
                <LoadingButton isLoading={btnLoading} onClick={() => handleApply(filters)} title='Apply Filter' />

                <button className='btn backBtn ' onClick={resetFilter}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </Menu>

        <Collapse in={isFiltered}>
          <div style={{ gap: '10px' }} className='d-flex flex-wrap align-items-center pb-3'>
            {filters?.stocktype ? (
              <span className='filter-tab-div '>
                Source of Stock:{filters?.stocktype}
                <HighlightOffIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                    color: '#214F8A'
                  }}
                  onClick={() => removeFilters('stocktype')}
                />
              </span>
            ) : null}
            {filters?.expiry ? (
              <span className='filter-tab-div'>
                Stock Expiry:{filters?.expiry}
                <HighlightOffIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                    color: '#214F8A'
                  }}
                  onClick={() => removeFilters('expiry')}
                />
              </span>
            ) : null}
            {filters?.inventorytype ? (
              <span className='filter-tab-div'>
                {filters?.inventorytype}
                <HighlightOffIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                    color: '#214F8A'
                  }}
                  onClick={() => removeFilters('inventorytype')}
                />
              </span>
            ) : null}

            {filters?.search ||
            filters?.stocktype ||
            filters?.search_main ||
            filters?.inventorytype ||
            filters?.expiry ? (
              <div
                style={{
                  color: '#214F8A',
                  fontSize: '1rem',
                  cursor: 'pointer'
                }}
                onClick={resetFilter}
              >
                Clear Filter
              </div>
            ) : null}
          </div>
        </Collapse>

        <div style={{ marginTop: syncLoading ? '2rem' : '0' }}>
          <Collapse in={syncLoading}>
            <Alert severity='info'>
              <p className='loader_syncp'>Sync In-Progress...</p>
            </Alert>
          </Collapse>
          <Collapse in={syncDone}>
            <Alert severity='success'>Stock Synced Succesfully</Alert>
          </Collapse>
        </div>
        <div className='bloodbank_action '>{/* <p>Blood Stock</p> */}</div>
        <div className='bloodbank_actions align-items-start'>
          <div>
            <div className='filters-container-div   hide_scroll'>
              <FiltersDonor
                icon={'/assets/images/all-donor-filters/Ttiblood.svg'}
                bgColor='linear-gradient(134deg, #EEE8D9 0%, #F0F3F5 100%)'
                imgbg='#D4BD86'
                title={allData?.testedCount || '00'}
                subtitle='Tested'
                onClick={e => handleInvTypeChange('tested')}
                cla={filters?.inventorytype === 'tested' ? 'filter-ative' : ''}
              />
              <FiltersDonor
                icon={'/assets/images/waste-management/Pending%20Autoclave.svg'}
                iconSize={{ width: '50px', height: '50px' }}
                bgColor='linear-gradient(134deg, #EFDADA 0%, #F0F3F5 100%)'
                title={allData?.untestedCount || '00'}
                imgbg='#93AED7'
                subtitle='Untested'
                onClick={e => handleInvTypeChange('untested')}
                cla={filters?.inventorytype === 'untested' ? 'filter-ative' : ''}
              />
              <FiltersDonor
                icon={'/assets/images/all-donor-filters/pendingblood.svg'}
                bgColor='linear-gradient(134deg, #D9E6EE 0%, #F0F3F5 100%)'
                imgbg='#7FB9DC'
                title={allData?.reserveCount || '00'}
                subtitle='Reserve'
                onClick={e => handleInvTypeChange('reserve')}
                cla={filters?.inventorytype === 'reserve' ? 'filter-ative' : ''}
              />
              <FiltersDonor
                icon={'/assets/images/Requistion/allRequest.svg'}
                // iconSize={{ width: '40px', height: '40px' }}
                bgColor='linear-gradient(98deg, #D8F4EF 0.4%, #F0F3F5 100%)'
                imgbg='#95CDC5'
                title={allData?.allCount || '00'}
                subtitle='All'
                onClick={e => handleInvTypeChange('')}
                cla={filters?.inventorytype === '' ? 'filter-ative' : ''}
              />
            </div>
          </div>

          <div className='d-flex align-items-end' style={{ flexDirection: 'column', gap: '4px' }}>
            <Tooltip title='Sync Stock with eRaktKosh'>
              <IconButton
                onClick={() => setOpenConfirm(true)}
                color='primary'
                sx={{ ...active, borderRadius: 1, maxHeight: '40px' }}
              >
                <SyncIcon />
              </IconButton>
            </Tooltip>
            <div
              style={{
                color: '#0b150fb2',
                fontSize: '13px',
                fontWeight: '600',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
              }}
            >
              <div>{lastsync ? 'Last Sync Date' : null}</div>
              <div>{lastsync ? dayjs(lastsync?.createdAt).format('DD/MM/YYYY hh:mm A') : null}</div>
            </div>
          </div>
        </div>

        <Card
          sx={{
            mt: 2,
            boxShadow: '0px 4px 14px 0px #00000024'
          }}
        >
          <div className='table-responsive stock_table pt-3'>
            <ScrollBar>
              <table className='table table-hover'>
                <thead>
                  <tr className='table_header'>
                    {columns.length > 0 &&
                      columns.map((val, idx) => (
                        <th key={idx}>
                          {val}
                          {/* {idx === 0 || idx === 1 ? val : val} */}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.map(item => (
                    <tr>
                      {columns.map((key, i) => (
                        <td
                          style={{
                            cursor: i === 0 || i === 1 || item?.type === 'Total' ? 'auto' : 'pointer'
                          }}
                          key={i}
                          onClick={() => {
                            if (i === 0 || i === 1 || item?.type === 'Total') return
                            history.push(
                              `/blood-stock/${columns[i]}/${item?.type}?inventorytype=${filters?.inventorytype}`
                            )
                          }}
                          className={`${item[key] < 6 && item[key] ? 'danger-blood-stock' : ''}`}
                        >
                          {!item[key] ? '-' : item[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ScrollBar>
          </div>
        </Card>
      </ScrollCard>

      <ModalCustom
        open={openConfirm}
        size='sm'
        title='Are you sure you want to sync the stock?'
        closeDialog={() => setOpenConfirm(false)}
        dialogStyle={{
          px: 2,
          pt: 1.5,
          pb: 0
        }}
        scroll='paper'
        actionComponent={
          <ModalActions>
            <div className='d-flex justify-content-end align-items-end p-3 '>
              <button type='button' className='btn backBtn mr-2 ' onClick={() => setOpenConfirm(false)}>
                Cancel
              </button>
              <LoadingButton onClick={() => handleSync()} isLoading={syncLoading} title='Save' />
            </div>
          </ModalActions>
        }
      >
        <Typography gutterBottom sx={{ textTransform: 'none' }}>
          <span style={{ fontWeight: '600' }}>Note:</span>
          Sync your current stock with eRaktKosh! Please click on the save button to Confirm.
        </Typography>
      </ModalCustom>
    </div>
  )
}

export default BloodComponent
