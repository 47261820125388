import React, { useState } from 'react'
import { Box, Button, Modal } from '@mui/material'
import dayjs from 'dayjs'
import LoadingButton from '../../shared/LoadingButton'

export const ReportPrev = ({ pdfBlob, filename }) => {
  const downloadBlob = filename => {
    const url = URL.createObjectURL(pdfBlob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${filename}-${dayjs().format('DD-MMM-YYYY')}.pdf` || 'sales-report.pdf'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  console.log(`pdf blob`, pdfBlob)

  return (
    <Box>
      {pdfBlob ? (
        <iframe src={URL.createObjectURL(pdfBlob)} style={{ width: '100%', height: '70vh' }} title='PDF Preview' />
      ) : null}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton style={{ marginTop: '1rem' }} title='Donwload' onClick={() => downloadBlob(filename)} />
      </Box>
    </Box>
  )
}
