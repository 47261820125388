import { BloodtypeOutlined, Launch, VolunteerActivismOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import BloogGroupPrintRH from '../../../../shared/BloogGroupPrintRH'
import { HoverCard } from '../shared/hover-card'
import { InfoItem } from '../shared/info-item'
import dayjs from 'dayjs'
import ModalCustom from '../../../../shared/ModalCustom'
import { EditCampInfo } from './component/edit-camp-info'
import { useState } from 'react'

const isCampEntity = CampEntity => {
  if (!CampEntity?.id) return false
  return true
}

export const DonorInfoSection = ({ data, saveEditModal }) => {
  const CampEntity = data?.BloodBagIssueEntity?.CampEntity
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [showDonationType, setShowDonationType] = useState(true)

  const openEditModal = (donation_type = true) => {
    setShowDonationType(donation_type)
    setIsOpen(true)
  }

  const cancelEditModel = () => {
    setIsOpen(false)
  }
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '1rem'
      }}
    >
      <ModalCustom
        // confirmDialog={saveEditModal}
        closeDialog={cancelEditModel}
        title={'Edit Camp Details'}
        open={isOpen}
      >
        <EditCampInfo
          showDonationType={showDonationType}
          data={data}
          confirmDialog={saveEditModal}
          closeDialog={cancelEditModel}
        />
      </ModalCustom>
      <div>
        <div
          className='profile-description1 profile-picc'
          style={{
            height: '200px'
            //  backgroundColor: '#E9EDF3'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <img
              style={{
                height: '50%',
                borderRadius: '1rem'
              }}
              src={
                data?.gender
                  ? data?.gender === 'Male'
                    ? `/assets/images/avatars/1.png`
                    : `/assets/images/avatars/8.png`
                  : `/assets/images/donor_profile/profile.png`
              }
              alt='profile-pic'
            />

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem'
              }}
            >
              <div style={{ display: 'flex', fontSize: '14px' }}>
                <Tooltip title='Gender' className='general-black'>
                  <img
                    src={'/assets/images/contact/gender-neutral.svg'}
                    style={{ width: '15px', height: '15px', marginRight: '5px', textWrap: 'wrap' }}
                    alt='Gender'
                  />
                </Tooltip>
                <span>{data?.gender || 'N/A'}</span>
              </div>
              <div style={{ display: 'flex', fontSize: '14px' }}>
                <Tooltip title='Blood Group' className='general-black'>
                  <img
                    src={'/assets/images/all-donor-filters/Ttiblood.svg'}
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '5px',
                      textWrap: 'wrap',
                      filter: 'grayscale(100%)'
                    }}
                    alt='Blood Group'
                  />
                </Tooltip>
                <span>
                  {data?.blood_group ? <BloogGroupPrintRH bloodGroup={data?.blood_group} /> : 'N/A'}
                  {/* {data?.blood_group || 'N/A'} */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='profile-description1' style={{ height: '200px' }}>
          {/* <p className='general-black'>Basic Information</p> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {data?.abha_id ? (
              <Tooltip className='general-black' title={'Go To Donor Profile'}>
                <div
                  onClick={() => {
                    history.push(`/donors-detail/${data?.abha_id}`)
                  }}
                  className='pic-name'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '0.5rem',
                    cursor: 'pointer'
                  }}
                >
                  <div className='pic-name'>{data?.name || 'N/A'}</div>
                  <Launch />
                </div>
              </Tooltip>
            ) : (
              <div className='pic-name'>{data?.name || 'N/A'}</div>
            )}
          </div>
          {/* {currenttab === 1 && isOpen ? null : (
                <div
                  style={{
                    color: '#13BCAB',
                    fontSize: '14px',
                    fontWeight: 500,
                    fontFamily: 'inter',
                    textDecoration: 'underline',
                    marginLeft: '5px',
                    cursor: 'pointer',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                  }}
                  onClick={() => {
                    setOpen(true)
                    // getBagInfo('timeline')
                  }}
                >
                  View Timeline
                </div>
              )} */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '1rem',
              marginTop: '1rem'
            }}
          >
            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/contact/phone-call.svg'}
                  style={{ width: '15px', height: '15px', marginRight: '5px', textWrap: 'wrap' }}
                  alt='Mobile'
                />
              )}
              title='Mobile'
            >
              {data?.mobile ? `+91 ${data?.mobile}` : 'N/A'}
            </InfoIconValue>

            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/contact/mail.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Email'
                />
              )}
              title='Email'
            >
              {data?.email || 'N/A'}
            </InfoIconValue>

            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/contact/calendar (1).svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='DoB / Age'
                />
              )}
              title='DoB / Age'
            >
              {data?.dob ? `${moment(data?.dob).format('DD MMM YYYY')}(${data?.age}yrs)` : `${data?.age || 'N/A'} Yr`}
            </InfoIconValue>

            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/contact/marriage.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Martial Status'
                />
              )}
              title='Martial Status'
            >
              {data?.martial_status === 'Married' ? 'Yes' : 'No' || 'N/A'}
            </InfoIconValue>

            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/bag-detail/Voluntry.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Martial Status'
                />
              )}
              title='Donation Type'
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '0.5rem'
                }}
              >
                <span>{data?.donation_type || 'N/A'}</span>
                <Tooltip className='general-black' title={'Edit'}>
                  <img
                    src={'/assets/images/editIcon.svg'}
                    alt='edit'
                    className='edit'
                    style={{ cursor: 'pointer', width: '16px', height: '16px' }}
                    name='edit'
                    onClick={openEditModal}
                  />
                </Tooltip>
              </div>
            </InfoIconValue>
            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/bag-detail/report.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'invert(1)',
                  }}
                  alt='Martial Status'
                />
              )}
              title='Donation Site'
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '1rem'
                }}
              >
                {isCampEntity(CampEntity) ? <CampInfoSection data={CampEntity} /> : 'In-House'}
                <Tooltip className='general-black' title={'Edit'}>
                  <img
                    src={'/assets/images/editIcon.svg'}
                    alt='edit'
                    className='edit'
                    style={{ cursor: 'pointer', width: '16px', height: '16px' }}
                    name='edit'
                    onClick={() => openEditModal(false)}
                  />
                </Tooltip>
              </div>
            </InfoIconValue>

            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/contact/husbands 1.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Spouse Name'
                />
              )}
              title='Spouse Name'
            >
              {data?.spouse_name || 'N/A'}
            </InfoIconValue>

            <InfoIconValue
              Icon={() => (
                <img
                  src={'/assets/images/bag-detail/In house.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Location'
                />
              )}
              title='Location'
            >
              {`${data?.area || ''} ${data?.city || ''} ${data?.district || ''} ${data?.state || ''} India ${data?.pincode || ''}`}
            </InfoIconValue>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CampInfoSection = ({ data }) => {
  return (
    <div>
      {`Camp - ${data?.camp_name}`}
      <HoverCard>
        <div style={{ display: 'grid', gap: '1rem', columnGap: '1.5rem', gridTemplateColumns: '1fr 1fr' }}>
          <InfoItem title='Organizer' value={data?.organizer_name || '-'} />
          {/* <InfoItem title='Organizer Contact'>
            {data?.organizer_mobile || '-'} {data?.organizer_mobile && data?.organizer_email ? ', ' : ''}{' '}
            {data?.organizer_email ? data?.organizer_email : null}
          </InfoItem> */}
          <InfoItem title='Contact'>{data?.organizer_mobile || '-'}</InfoItem>

          <InfoItem title='Email'>{data?.organizer_email || '-'}</InfoItem>
          <InfoItem
            title='Date'
            value={data?.camp_schedule_date ? dayjs(data?.camp_schedule_date).format('DD/MM/YYYY') : null}
          />
          <InfoItem title='Timing' value={`${data?.camp_start_timing} - ${data?.camp_ending_time}`} />
          {/* <InfoItem title='Location' value={data?.address} /> */}
          <InfoItem title='City' value={data?.city} />
          {/* <InfoItem title='Camp Purpose' value={data?.camp_purpose} />
          <InfoItem title='Expected Unit Collection' value={data?.expected_unit_collection} /> */}
        </div>
      </HoverCard>
    </div>
  )
}

export const InfoIconValue = ({ Icon, children, title }) => {
  return (
    <div
      style={{ display: 'flex', fontSize: '14px', gap: '0.5rem', justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <Tooltip title={title} className='general-black'>
        {Icon && <Icon />}
      </Tooltip>
      <span>{children}</span>
    </div>
  )
}
