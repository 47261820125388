export const HoverCard = ({ children }) => {
  return (
    <span className='hover-image'>
      <img
        src={'/assets/images/Patient Info-1.svg'}
        style={{ width: '12px', height: '12px', position: 'relative' }}
        className='hover-image'
        alt='info'
      />
      <div className='info'>{children}</div>
    </span>
  )
}
