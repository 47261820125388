import React from 'react'
import './common.css'
import moment from 'moment'
import { useState, useEffect } from 'react'
import DonorTopModal from './DonorTopModal'
const DonorTop = ({ basicInfo, info }) => {
  const [open, setOpen] = useState(false)
  const [pic, setPic] = useState('1')

  //console.log(curr_status);

  const donorbasicDetails = [
    { label: 'Registration Id', values: basicInfo?.reg_id || 'NA' },
    { label: 'ABHA Id', values: basicInfo?.abha || 'NA' },
    { label: 'Aadhaar Id', values: basicInfo?.adhar || 'NA' },
    {
      label: 'DOB',
      values: basicInfo?.dob ? moment(basicInfo?.dob).format('DD-MMM-YYYY') : 'NA'
    },
    {
      label: 'Mobile Number',
      values: basicInfo?.mobile ? <>+91 {basicInfo?.mobile}</> : 'NA'
    },
    { label: "Father's Name", values: basicInfo?.father_name || 'NA' },
    { label: 'Martial Status', values: basicInfo?.martial_status || 'NA' },
    { label: 'Spouse Name', values: basicInfo?.spouse_name || 'NA' },
    { label: 'City', values: basicInfo?.city || 'NA' },
    { label: 'District', values: basicInfo?.district || 'NA' },
    { label: 'State', values: basicInfo?.state || 'NA' },
    { label: 'Pin code', values: basicInfo?.pincode || 'NA' }
  ]

  const subheading = basicInfo?.gender + ' | ' + basicInfo?.age + ' Yr '

  const modaldata = {
    basic: donorbasicDetails,
    name: basicInfo?.name,
    subheading: subheading
  }

  const closeDialog = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (basicInfo?.gender && basicInfo?.gender !== 'Male') {
      setPic('8')
    } else {
      setPic('1')
    }
  }, [basicInfo?.gender])

  return (
    <div className='main_profile'>
      <DonorTopModal data={modaldata} open={open} closeDialog={closeDialog} />
      <div className='dh-pr-section'>
        <img
          src={`/assets/images/avatars/${pic}.png`}
          alt=''
          style={{ width: '80px', height: '80px', borderRadius: '8px' }}
        />
        <div className='d-history-profile-details'>
          <div className='d-history-profile-name'>{basicInfo?.name || 'N/A'}</div>
          <div className='d-history-profile-age'>
            {basicInfo?.gender || 'N/A'} | {basicInfo?.age} Yr
          </div>
          <div className='d-history-profile-name-view-more' onClick={() => setOpen(true)}>
            View More
          </div>
        </div>
      </div>

      <div className='add_info'>
        <div className='info_1'>
          {info &&
            info?.map((ele, i, row) => {
              if (i + 1 === row.length) {
                return (
                  <div className='info_col'>
                    <span className='cal_info'>{ele?.label}</span>
                    <span className='col_title'>{ele.value || 'N/A'}</span>
                  </div>
                )
              } else {
                return (
                  <div className='info_col'>
                    <span className='cal_info'>{ele?.label}</span>
                    <span className='col_title'>{ele.value || 'N/A'}</span>
                  </div>
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}

export default DonorTop
