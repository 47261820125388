import { useState } from 'react'
import { downLoadFiles, getToken } from '../../middleware/auth'
import ModalCustom from '../../shared/ModalCustom'
import { ReportPrev } from './report'
import axios from 'axios'
import { API_URL } from '../../../helper/AxiosHelper'
import Loader from '../../shared/Loader'

export const ReportPreview = ({ url, children, title, filename }) => {
  const [pdfBlob, setPdfBlob] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [showPrev, setShowPrev] = useState(false)

  const fetchBlob = async url => {
    try {
      if (!url) {
        throw new Error('Please provide a valid url')
      }
      const res = await axios({
        url: API_URL + url,
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        method: 'GET',
        responseType: 'blob'
      })

      const blob = new Blob([res.data], {
        type: res.headers['content-type']
      })

      return blob
    } catch (error) {
      throw new Error(error)
    }
  }
  const handleReport = async url => {
    try {
      if (url) {
        const blob = await fetchBlob(url)
        if (!blob) {
          throw new Error('No data found')
        }
        setPdfBlob(blob)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handlePreview = async () => {
    setLoading(true)
    await handleReport(url)
    setShowPrev(true)
  }
  return (
    <>
      <Loader isLoading={isLoading} />

      <ModalCustom
        open={showPrev}
        closeDialog={() => setShowPrev(false)}
        scroll='paper'
        size='lg'
        title={title || 'Report'}
        dialogStyle={{
          pt: 0,
          px: 4
        }}
      >
        <ReportPrev filename={filename} pdfBlob={pdfBlob} />
      </ModalCustom>
      <div onClick={handlePreview}>{children}</div>
    </>
  )
}
