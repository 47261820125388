import { FormHelperText, FormLabel, Grid, MenuItem } from '@mui/material'
import FormProvider from '../../../../../shared/FormProvider'
import { inputStyles, labelStyles } from '../../../../../certification/EditCertificate'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import RHFSelect from '../../../../../shared/RHFSelect'
import { useEffect, useState } from 'react'
import AxiosHelper from '../../../../../../helper/AxiosHelper'
import Loader from '../../../../../shared/Loader'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

export const updateCampInfo = async (camp_id, bag_id) => {
  try {
    const { data } = await AxiosHelper.put(`blood-bag-issue/${bag_id}`, { camp_id })

    if (!data) {
      throw new Error('No data found')
    }

    console.log(`Update Camp Info: `, data)
  } catch (error) {
    console.log(error)
  }
}

export const EditCampInfo = ({ closeDialog, confirmDialog, data, showDonationType = true }) => {
  const CampEntity = data?.BloodBagIssueEntity?.CampEntity
  const bag_id = data?.BloodBagIssueEntity?.id
  const abha_id = data?.abha_id

  const [isLoading, setIsLoading] = useState(false)
  const [camps, setCamps] = useState([])

  const defaultValues = {
    donation_type: data?.donation_type || '',
    camp_id: CampEntity?.id || 'in_house'
  }

  const schema = yup.object().shape({
    donation_type: yup.string(),
    camp_id: yup.string()
  })

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors }
  } = methods

  const fetchAllCamps = async () => {
    try {
      setIsLoading(true)
      const { data } = await AxiosHelper.get('camp/without-pagination')

      if (!data || !data?.data) {
        throw new Error('No data found')
      }

      /*
      // Filter out camps that are older than 3 months
      const allcamps = data?.data?.filter(camp => {
        const campDate = new Date(camp.camp_schedule_date)
        const now = new Date()
        const threeMonthsAgo = new Date()
        threeMonthsAgo.setMonth(now.getMonth() - 3)
        
        return campDate >= threeMonthsAgo
      })
      */

      const allcamps = data?.data

      // if (!CampEntity?.id) {
      //   setCamps([
      //     ...allcamps,
      //     {
      //       id: 'in_house',
      //       camp_name: 'In-House'
      //     }
      //   ])
      // } else {
      //   setCamps(allcamps)
      // }

      setCamps([
        ...allcamps,
        {
          id: 'in_house',
          camp_name: 'In-House'
        }
      ])

      console.log(`All Camps: `, data)

      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async formData => {
    const { camp_id, donation_type } = formData
    console.log(`submit data: `, formData)
    if (!showDonationType) {
      await confirmDialog({ camp_id, bag_id, new_date: data?.BloodBagIssueEntity?.assigned_date })
      toast.success('Blood Camp updated successfully')
    } else {
      await confirmDialog({ abha_id, donation_type })
      toast.success('Donation Type updated successfully')
    }
    closeDialog()
  }

  useEffect(() => {
    if (!showDonationType) {
      fetchAllCamps()
    }
  }, [showDonationType])

  console.log(`Camp Info Data: `, data?.BloodBagIssueEntity?.assigned_date)

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Loader isLoading={isLoading} />
      <Grid container spacing={3}>
        {showDonationType ? (
          <Grid item md={12}>
            <FormLabel sx={labelStyles}>Change Donation Type</FormLabel>
            <RHFSelect sx={inputStyles} fullWidth size='small' placeholder='Change Donation Type' name='donation_type'>
              <MenuItem value='Voluntary'>Voluntary Donor</MenuItem>
              <MenuItem value='Replacement'>Replacement Donor</MenuItem>
              <MenuItem value='Autologous'>Autologous Donor</MenuItem>
            </RHFSelect>
            <FormHelperText
              style={{
                fontWeight: 'bold',
                fontStyle: 'italic'
              }}
            >
              Your selection would change the Donation Type.
            </FormHelperText>
          </Grid>
        ) : (
          <Grid item md={12}>
            <FormLabel sx={labelStyles}>Change Blood Camp</FormLabel>
            <RHFSelect sx={inputStyles} fullWidth size='small' placeholder='Change Blood Camp' name='camp_id'>
              {camps.map((item, idx) => (
                <MenuItem key={idx} value={item.id}>
                  {item.camp_name}{' '}
                  {item?.camp_schedule_date ? `(${dayjs(item?.camp_schedule_date).format('DD-MMM-YYYY')})` : ''}
                </MenuItem>
              ))}
            </RHFSelect>
            <FormHelperText
              style={{
                fontWeight: 'bold',
                fontStyle: 'italic'
              }}
            >
              Your selection would change the Blood Camp.
            </FormHelperText>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          marginTop: '1rem',
          justifyContent: 'flex-end'
        }}
      >
        <button type='button' className='btn backBtn' onClick={closeDialog}>
          Close
        </button>
        <button type='submit' className='btn backBtn btn-gradient-custom'>
          Save
        </button>
      </div>
    </FormProvider>
  )
}
