import axios from 'axios'
import AxiosHelper, { API_URL } from './AxiosHelper'
import { getTenatIdSans, getTenatNameSans, getToken } from '../app/middleware/auth'
import pdfIcon from '../assets/images/file-icons/pdf.png'
import docIcon from '../assets/images/file-icons/doc.png'
import xlsIcon from '../assets/images/file-icons/xls.png'
import csvIcon from '../assets/images/file-icons/csv.png'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
export async function handleGetAPI(url) {
  try {
    const response = await axios.get(
      API_URL + url,
      {
        headers: { Authorization: `Bearer ${getToken()}` }
      },
      { responseType: 'blob' }
    )
    if (response.data) {
      return response.data
    }
  } catch (e) {
    console.log(e)
  }
}

export function printAll(elementId, uniqueIframeId) {
  const content = document.getElementById(elementId)
  let pri
  if (document.getElementById(uniqueIframeId)) {
    pri = document.getElementById(uniqueIframeId).contentWindow
  } else {
    const iframe = document.createElement('iframe')
    iframe.setAttribute('title', uniqueIframeId)
    iframe.setAttribute('id', uniqueIframeId)
    iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
    document.body.appendChild(iframe)
    pri = iframe.contentWindow
  }
  pri.document.open()
  pri.document.write(content.innerHTML)
  pri.document.close()
  pri.focus()
  pri.print()
}

export const handlePrintWithBlob = async blob => {
  if (!blob) return
  const url = URL.createObjectURL(blob)
  const iframe = document.createElement('iframe')
  iframe.style.position = 'fixed'
  iframe.style.width = '100%'
  iframe.style.height = '100%'
  iframe.src = url
  document.body.appendChild(iframe)
  iframe.onload = () => {
    iframe.contentWindow.print()
  }
}

export const bloodGroupApiToUser = {
  'AB-Ve': 'AB-',
  'AB+Ve': 'AB+',
  'A+Ve': 'A+',
  'A-Ve': 'A-',
  'B+Ve': 'B+',
  'B-Ve': 'B-',
  'O-Ve': 'O-',
  'O+Ve': 'O+',
  'OH-Ve': 'OH-',
  'OH+Ve': 'OH+'
}

export const bloodGroupApiToBarcode = {
  'AB-Ve': 'AB',
  'AB+Ve': 'AB',
  'A+Ve': 'A',
  'A-Ve': 'A',
  'B+Ve': 'B',
  'B-Ve': 'B',
  'O-Ve': 'O',
  'O+Ve': 'O',
  'OH-Ve': 'OH',
  'OH+Ve': 'OH'
}

export const bloodGroupAll = [
  { value: 'AB-Ve', label: 'AB-' },
  { value: 'AB+Ve', label: 'AB+' },
  { value: 'A+Ve', label: 'A+' },
  { value: 'A-Ve', label: 'A-' },
  { value: 'B+Ve', label: 'B+' },
  { value: 'B-Ve', label: 'B-' },
  { value: 'O-Ve', label: 'O-' },
  { value: 'O+Ve', label: 'O+' }
]

export function debouncing(func, wait, immediate) {
  var timeout

  return (...args) => {
    var context = this

    var later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

export const getFileTypeFromUrl = file => {
  if (!file) return
  if (/\.(jpg|jpeg|png|webp|avif|gif|svg|PNG|SVG|JPG|JPEG)/i.test(file)) {
    return 'Image'
  }

  let fileType = file?.split('.').pop()
  if (fileType === 'pdf' || fileType === 'PDF') {
    fileType = 'PDF'
  } else if (fileType === 'word' || fileType === 'WORD') {
    fileType = 'Word'
  } else if (fileType === 'excel' || fileType === 'EXCEL' || fileType === 'xlsx' || fileType === 'XLSX') {
    fileType = 'Excel'
  } else if (fileType === 'txt' || fileType === 'TXT') {
    fileType = 'Txt'
  } else if (
    fileType === 'csv' ||
    fileType === 'CSV' ||
    fileType === 'xls' ||
    fileType === 'XLS' ||
    fileType === 'xlsx' ||
    fileType === 'XLSX'
  ) {
    fileType = 'Csv'
  } else {
    fileType = 'Other'
  }

  return fileType
}

export const getFileTypeFromFile = file => {
  if (!file) return
  if (/\.(jpg|jpeg|png|webp|avif|gif|svg|PNG|SVG|JPG|JPEG)$/.test(file.name)) {
    return 'Image'
  }
  let fileType = file.name?.split('.').pop()
  if (fileType === 'pdf' || fileType === 'PDF') {
    fileType = 'PDF'
  } else if (fileType === 'word' || fileType === 'WORD') {
    fileType = 'Word'
  } else if (fileType === 'excel' || fileType === 'EXCEL') {
    fileType = 'Excel'
  } else if (fileType === 'csv' || fileType === 'CSV') {
    fileType = 'Csv'
  } else if (fileType === 'txt' || fileType === 'TXT') {
    fileType = 'Txt'
  } else {
    fileType = 'Other'
  }

  return fileType
}

export const getFileIcon = fileType => {
  if (fileType === 'PDF') {
    return pdfIcon
  } else if (fileType === 'Word') {
    return docIcon
  } else if (fileType === 'Excel') {
    return xlsIcon
  } else if (fileType === 'Csv') {
    return csvIcon
  } else if (fileType === 'Txt') {
  } else {
    return docIcon
  }
}

let keys = ['ArrowUp', 'ArrowDown', 'e', 'E']

export function onKeyDown(e) {
  keys.includes(e.key) && e.preventDefault()
}

export function RupeeToWords(num) {
  const single = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  const double = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ]
  const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  const formatTenth = (digit, prev) => {
    return 0 === digit ? '' : ' ' + (1 === digit ? double[prev] : tens[digit])
  }
  const formatOther = (digit, next, denom) => {
    return (0 !== digit && 1 !== next ? ' ' + single[digit] : '') + (0 !== next || digit > 0 ? ' ' + denom : '')
  }
  let res = ''
  let index = 0
  let digit = 0
  let next = 0
  let words = []
  if (((num += ''), isNaN(parseInt(num)))) {
    res = ''
  } else if (parseInt(num) > 0 && num.length <= 10) {
    for (index = num.length - 1; index >= 0; index--)
      switch (((digit = num[index] - 0), (next = index > 0 ? num[index - 1] - 0 : 0), num.length - index - 1)) {
        case 0:
          words.push(formatOther(digit, next, ''))
          break
        case 1:
          words.push(formatTenth(digit, num[index + 1]))
          break
        case 2:
          words.push(
            0 !== digit
              ? ' ' + single[digit] + ' Hundred' + (0 !== num[index + 1] && 0 !== num[index + 2] ? '' : '')
              : ''
          )
          break
        case 3:
          words.push(formatOther(digit, next, 'Thousand'))
          break
        case 4:
          words.push(formatTenth(digit, num[index + 1]))
          break
        case 5:
          words.push(formatOther(digit, next, 'Lakh'))
          break
        case 6:
          words.push(formatTenth(digit, num[index + 1]))
          break
        case 7:
          words.push(formatOther(digit, next, 'Crore'))
          break
        case 8:
          words.push(formatTenth(digit, num[index + 1]))
          break
        case 9:
          words.push(
            0 !== digit
              ? ' ' + single[digit] + ' Hundred' + (0 !== num[index + 1] || 0 !== num[index + 2] ? ' and' : ' Crore')
              : ''
          )
          break

        default:
      }
    res = words.reverse().join('')
  } else res = ''
  return res
}

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()

  return (
    new Date(date).getDate() === today.getDate() &&
    new Date(date).getMonth() === today.getMonth() &&
    new Date(date).getFullYear() === today.getFullYear()
  )
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }
  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const legalFileDownload = filename => {
  if (filename) {
    AxiosHelper.post('download/download-file', { key: filename })
      .then(res => {
        const file = res.data.data
        legalFileDownloadLink(file)
      })
      .catch(error => {
        console.log('error', error)
        toast.error('File download failed. Please try again later.')
      })
  } else {
    toast.error('File download failed. Please try again later.')
  }
}

export const legalFileDownloadLink = async fileUrl => {
  const toastId = toast.loading('Downloading file, please wait')
  let defaultFileName = 'ehr-' + dayjs().format('DD-MM-YYYY_HH-mm') + `.pdf`
  try {
    const response = await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob' // Important: Ensure you specify the response type as 'blob'
    })

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: response.headers['content-type'] })

    // Create a link element
    const link = document.createElement('a')

    // Create a URL for the Blob and set it as the href attribute
    const url = window.URL.createObjectURL(blob)
    link.href = url

    // Set the download attribute with a filename
    link.setAttribute('download', defaultFileName) // You can specify any filename you prefer

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically click the link to trigger the download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
    toast.update(toastId, {
      render: 'Your file has been successfully downloaded',
      type: 'success',
      isLoading: false,
      autoClose: 2000
    })
  } catch (error) {
    toast.update(toastId, {
      render: 'File download failed. Please try again later.',
      type: 'error',
      isLoading: false,
      autoClose: 2000
    })
    console.error('Error downloading the file', error)
  }
}
export function checkTenantId(url, key = '') {
  if (getTenatNameSans() != '') {
    const separator = url.includes('?') ? '&' : '?'
    url = url + `${separator}${key || 'tenant_id'}=${getTenatIdSans()}`
    return url
  }
  return url
}

export function formatRelativeDate(date) {
  const now = dayjs()
  const targetDate = dayjs(date)

  const diffDays = now.diff(targetDate, 'day')

  if (diffDays === 0) {
    return 'today'
  } else if (diffDays === 1) {
    return 'yesterday'
  } else {
    return `${diffDays} days ago`
  }
}

export const getBagType = code => {
  // Define the mapping for each prefix to its corresponding bag type
  const bagTypeMap = {
    SDP: 1,
    SB: 1,
    DB: 2,
    TB: 3
  }

  // Extract the prefix from the code
  const prefix = code.split('-')[0]

  // Return the bag type based on the prefix
  return bagTypeMap[prefix] || 'unknown' // Return 'unknown' if the prefix is not found in the map
}

export const componentMapping = [
  { component: 'Whole Blood (CPD)', value: 'WB(CPD)' },
  { component: 'Whole Blood (CPDA-1)', value: 'WB(CPDA-1)' },
  { component: 'Fresh Frozen Plasma', value: 'FFP' },
  { component: 'Single Donor Platelet', value: 'SDP' },
  { component: 'Packed Red Blood Cells', value: 'PRBC' },
  { component: 'Cryoprecipitate', value: 'Cryoprecipitate' },
  { component: 'Plasma', value: 'Plasma' },
  { component: 'Platelet Concentrate', value: 'Platelet Concentrate' },
  { component: 'Cryo Poor Plasma', value: 'Cryo Poor Plasma' },
  { component: 'Random Donor Platelets', value: 'Random Donor Platelets' },
  { component: 'Platelets additive solutions', value: 'Platelets additive solutions' }
]

export const bagTypeItems = [
  'SDP',
  'SB-350 Vol-350',
  'SB-450 Vol-450',
  'DB-CPDA-350 Vol-350',
  'DB-CPDA-450 Vol-450',
  'DB-SAGM-350 Vol-350',
  'DB-SAGM-450 Vol-450',
  'TB-CPDA-350 Vol-350',
  'TB-CPDA-450 Vol-450',
  'TB-SAGM-350 Vol-350',
  'TB-SAGM-450 Vol-450'
]

export const requestType = [
  { label: 'Patient', value: 'PATIENT' },
  { label: 'Paediatric', value: 'PAEDIATRIC' },
  { label: 'Plasma', value: 'PLASMA' },
  { label: 'Bulk Transfer', value: 'BULKTRANSFER' }
]
export const requestTypeForApiCall = [
  { value: 'PT', label: 'PATIENT' },
  { value: 'PED', label: 'PAEDIATRIC' },
  { value: 'PL', label: 'PLASMA' },
  { value: 'BULK', label: 'BULK TRANSFER' },
  { value: 'BULKR', label: 'BULK RECIEVE' },
]
export const getAliquotLabels = (comp) => {
  const component = componentMapping.find(item => item.component === comp);
  return component ? `${component.value} Aliquot` : component ? component : "";
}

export const getAliquotLabelsKey = (comp, keyIdx) => {
  const component = componentMapping.find(item => item.component === comp);
  // Convert keyIdx to a character starting from 'A' (ASCII code 65)
  const aliquotLetter = String.fromCharCode(65 + keyIdx);
  return component ? `${component.value} Aliquot-${aliquotLetter}` : component ? component : "";
}

export const getComponentname = (comp, short = true) => {
  const component = componentMapping.find(item => item.component === comp);
  return short ? (component?.value) : (component?.component)
}

// Function to retrieve a value from localStorage with error handling
export const getLocalStorageItem = (key, defaultValue) => {
  try {
    const storedValue = localStorage.getItem(key)
    return storedValue !== null ? storedValue : defaultValue
  } catch (error) {
    console.error(`Error retrieving ${key} from localStorage:`, error)
    return defaultValue
  }
}

// Function to save a value to localStorage with error handling
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    console.error(`Error saving ${key} to localStorage:`, error)
  }
};

export const parseRequestId = (requestId, prefix = "", request_id = "") => {

  try {
    // Check if requestId is a valid string
    if (typeof requestId !== "string") {
      throw new Error("Input must be a string");
    }

    // If requestId is empty, return default values
    if (requestId.trim() === "") {
      return { prefix, request_id };
    }

    // Check if the string contains a hyphen and split accordingly
    if (requestId.includes("-")) {
      [prefix, request_id] = requestId.split("-");

      // Ensure both parts are valid strings
      if (typeof prefix !== "string" || typeof request_id !== "string") {
        throw new Error("Invalid split result");
      }
    } else {
      request_id = requestId;  // No hyphen, so the entire string is the request_id
    }

    return { prefix, request_id };

  } catch (error) {
    console.error("Error:", error.message);
    return { prefix: "", request_id: "" };  // Return empty values on error
  }
}

export const matchHospitalName = (searchString, hospitals) => {
  const hospital = hospitals.find(hospital =>
    hospital.hospital_name.toLowerCase().includes(searchString.toLowerCase())
  );

  return hospital?.id || ""
}

export const checkInvoiceHelper = async (invoice_number) => {
  const res = await AxiosHelper.post('billing-v2/check-invoice_number', {
    invoice_number
  })

  return res.data
}

export const getInvoicePrefixHelper = async (request_type,invoice_date) => {
  const res = await AxiosHelper.post('billing-v2/get-invoice_number', {
    request_type,
    invoice_date
  })

  return res.data
}

export const addNewHospital = async (data) => {
  const res = await AxiosHelper.post('hospital', { ...data })

  return res;
}

export function PendingImg({ onClick }) {
  return (
    <span className='status-pending' onClick={onClick}>
      <img src={'/assets/images/status/wall-clock.svg'} style={{ height: '16px', width: '16px' }} alt='' />
      Pending
    </span>
  )
}
export function DoneImg() {
  return (
    <span className='status-done'>
      <img src={'/assets/images/status/checkmark.svg'} style={{ height: '16px', width: '16px' }} alt='' />
      Done
    </span>
  )
}
export function ImgTableStatus({ path, title }) {
  return (
    <span className='status-done' style={{ padding: 0 }}>
      <img
        src={path || '/assets/images/status/checkmark.svg'}
        style={{ height: '16px', maxWidth: '20px', objectFit: 'contain', borderRadius: 0 }}
        alt=''
      />
      {title || ''}
    </span>
  )
}

export const formatAge = (age, type, is_short = false, reqType) => {
  if (!age) {
    return "";  // Return empty string if age is not present
  }

  // If type is not provided, handle based on reqType
  if (!type && reqType === 1) {
    return is_short ? `${age} Y` : `${age} Years`;  // Short form "Y" or full "Years"
  }

  if (!type && reqType === 2) {
    return is_short ? `${age} D` : `${age} Days`;  // Short form "D" or full "Days"
  }

  // If type is provided, use it to determine the unit
  switch (type) {
    case 'Y':
      return is_short ? `${age} Yr` : `${age} Years`;  // Short form "Y" or full "Years"
    case 'M':
      return is_short ? `${age} M` : `${age} Months`; // Short form "M" or full "Months"
    case 'D':
      return is_short ? `${age} D` : `${age} Days`;   // Short form "D" or full "Days"
    default:
      return "";  // If type is none of the expected values, return empty string
  }
}



export function debouncingFunction(func, wait, immediate) {
  let timeout;

  return function (...args) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function HighlightText({ text, searchTerm }) {
  if (!searchTerm) {
    return text
  }
  if (!text) {
    return
  }
  const regex = new RegExp(`(${searchTerm})`, 'gi');

  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <mark className="bg-warning" key={index}>{part}</mark>
        ) : (
          part
        )
      )}
    </span>
  );
}

export async function convertImageToBase64(imageUrl) {
  try {
    // Fetch the image using axios and set responseType to 'blob'
    const response = await axios.get(imageUrl, { responseType: 'blob' });

    // Convert the blob to Base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(response.data); // read the blob data
    });
  } catch (error) {
    console.error('Error converting image to Base64:', error);

  }
}

 export const getAuthPrice = ()=>{
   const authDataString = localStorage.getItem('authInfo') || {};
   if (authDataString) {
    const authData = JSON.parse(authDataString);
    const tenantInfo = authData.tenantInfo;
    // console.log('Tenant Info:',typeof(tenantInfo.type));
  return  tenantInfo?.type != 1 ? false : true;
  } 
 }

export const usersListByRole = (role) => {
  try {
    const auth = getLocalStorageItem('authInfo', '[]')
    const users = JSON.parse(auth)
    const allUsers = users?.users || []
    if (!Array.isArray(allUsers)) {
      throw new Error('Invalid users data')
    }

    const structuredUsers = allUsers.flatMap(user =>
      user.UserDetailsEntities.map((detail, i) => ({
        id: detail.UserEntity?.name,
        role: user.name,
        label: `${detail.UserEntity?.name} (${user.name || ''})`,
      }))
    )

    if (role) {
      const filteredUsers = structuredUsers.filter(user => user.role === role)
      return filteredUsers
    } else {
      return structuredUsers
    }
  } catch (error) {
    console.error('Error fetching users by role:', error)
    return []
  }
}

export const convertLabelViaList = (list, key, value, displayValue, defaultKey) => {
  try {
    const check = list.find((el) => el[key] == value)
    return check == null ? defaultKey : displayValue
  }
  catch (error) {
    console.log(`error:${error}`)
    return defaultKey
  }
}

export const validatePrefix = (inputValue,defaultPrefix) =>{
  try {
   if(inputValue.includes('-')){
    if(inputValue.split('-').length>2){
      return false
    }
    // const prefix = inputValue.split('-')[0]
    // if(defaultPrefix==prefix){
    //  return true
    // }
    return true
   }
   return false
  }
  catch (error) {
    console.log(`error:${error}`)
    return false
  }
}   
export const blod_bag_number_regex = /^[^-]*-?[^-]*$/;

export const aplpaNum_regex = /^[a-zA-Z0-9]+$/;

export const no_space_regex= /^\S+$/