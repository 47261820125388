import { useEffect, useState } from 'react'
import ModalCustom from '../../../../shared/ModalCustom'
import { CardHeader } from '../shared/card-header'
import { InfoItem } from '../shared/info-item'
import { EditTTIForm } from './component/tti-edit'
import AxiosHelper from '../../../../../helper/AxiosHelper'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { StatusCapsule } from '../shared/status-capsule'

export const editTTI = async (id, bodyData) => {
  const res = await AxiosHelper.put(`blood-testing/${id}/tti-edit`, bodyData)
  const { data } = await res
  return data
}

// Helper function to determine the result based on testing values and kit type
export const getTestingResult = (value, kitType) => {
  if (!value) return 'Non-Reactive'
  if (value === '0') {
    return kitType === 'ELISA Kit' ? 'Non-Reactive' : 'Negative'
  }
  return kitType === 'ELISA Kit' ? 'Reactive' : 'Positive'
}

// Mapping of test titles to their respective keys in BloodTestingEntity
export const testMappings = [
  { title: 'HIV', key: 'hiv', kitKey: 'hivkit', label: 'HIV Kit' },
  { title: 'HBsAg (Hepatitis B)', key: 'hsbg', kitKey: 'hepatitisbkit', label: 'Hepatitis-B Kit' },
  { title: 'Anti-HCV (Hepatitis C)', key: 'anty_hcv', kitKey: 'hepatitisckit', label: ' Hepatitis-C Kit' },
  { title: 'Syphilis(VDRL)', key: 'vdrl', kitKey: 'syphiliskit', label: 'Syphilis (VDRL) Kit' },
  { title: 'Malaria', key: 'malaria', kitKey: 'malariakit', label: 'Malaria Kit' }
]

export const TTI = ({ trailData, BloodTestingEntity, getAllotedComponents, getDonorHistory, getdataInfo, info }) => {
  const [openEditModal, setOpenEditModal] = useState(false)
  const [disableEdit, setDisableEdit] = useState(false)
  const [ttiData, setTtiData] = useState(BloodTestingEntity)
  const { id } = useParams()
  const history = useHistory()

  const actionCallBack = async e => {
    if (e.target.name === 'edit') {
      setOpenEditModal(true)
    }
  }

  const saveEditModal = async formData => {
    try {
      const updatedTTIData = { ...ttiData, ...formData }
      setTtiData(updatedTTIData)
      console.log(`saving tti -> `, ttiData)
      const res = await editTTI(updatedTTIData?.id, formData)
      const { data, statusCode, message } = await res
      if (!data) {
        throw new Error('No Response from server!')
      }
      if (statusCode === 422) {
        throw new Error(message)
      }

      // if (id) {
      //   history.push(`/donors-detail/${id}`)
      //   window.location.reload()
      // }
    } catch (error) {
      console.log(error)
      toast.error(error || 'Something went wrong!')
    } finally {
      setOpenEditModal(false)
    }
  }

  const cancelEditModel = async () => {
    setOpenEditModal(false)
  }

  const checkDisable = () => {
    const BloodLogsEntities = trailData?.BloodBagIssueEntity?.BloodLogsEntities

    let boolVal
    let bool2Val = false

    BloodLogsEntities?.forEach((item, idx) => {
      if (item?.status == '2' || item?.status == '5') {
        bool2Val = true
      }
      boolVal =
        item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.is_deleted === true ||
        item?.BloodRequestLogsEntity?.is_deleted === true
          ? false
          : item?.PlasmaRequestLogEntity
            ? item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.issue_number
              ? true
              : false
            : item?.BloodRequestLogsEntity
              ? item?.BloodRequestLogsEntity?.issue_number
                ? true
                : false
              : item?.BloodLoanLogsEntity
                ? item?.BloodLoanLogsEntity?.issue_number
                  ? true
                  : false
                : false
    })

    if (boolVal || bool2Val) {
      setDisableEdit(true)
    }
  }

  useEffect(() => {
    checkDisable()
  }, [trailData])

  useEffect(() => {
    if (BloodTestingEntity) {
      setTtiData(BloodTestingEntity)
    }
  }, [BloodTestingEntity])

  console.log(`trailData tti -> `, trailData)

  return (
    <>
      <ModalCustom
        // confirmDialog={saveEditModal}
        closeDialog={cancelEditModel}
        title={'Edit TTI'}
        open={openEditModal}
      >
        <EditTTIForm BloodTestingEntity={ttiData} confirmDialog={saveEditModal} closeDialog={cancelEditModel} />
      </ModalCustom>
      <CardHeader
        deleteBtn={false}
        testingDate={ttiData?.testing_date}
        actionBtns
        disableEdit={disableEdit}
        editToolTip={disableEdit ? 'Component is already Issued or Transfered' : 'Edit'}
        actionCallBack={actionCallBack}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
          <div>
            TTI Results <span className='trail'>(Test By: {ttiData?.tested_by || '-'})</span>
          </div>
          <div>
            {trailData?.defer_type === '2' ? (
              <StatusCapsule status={'cancel'}>Permanent</StatusCapsule>
            ) : trailData?.defer_type === '1' ? (
              <StatusCapsule status={'pending'}>Partial</StatusCapsule>
            ) : null}
          </div>
        </div>
      </CardHeader>
      <div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: '1rem',
            padding: '0.5rem'
          }}
        >
          {testMappings.map(({ title, key, kitKey }, idx) => (
            <InfoItem key={title} title={`${title}`}>
              <div>
                {ttiData?.[key] == '0' ? (
                  <StatusCapsule status={'done'}>
                    {ttiData?.[kitKey] === 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}
                  </StatusCapsule>
                ) : null}
              </div>
              <div>
                {ttiData?.[key] == '1' ? (
                  <StatusCapsule status={'cancel'}>
                    {ttiData?.[kitKey] === 'ELISA Kit' ? 'Reactive' : 'Positive'}
                  </StatusCapsule>
                ) : null}
              </div>
            </InfoItem>
          ))}
        </div>
      </div>
    </>
  )
}
