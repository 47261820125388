import { useHistory } from 'react-router-dom'
import ToolTips from '../../../../common/ToolTips'

export const ProfileBagRedirect = ({ children, donor_id, bag_number, bag_id, style }) => {
  const history = useHistory()
  const handleRedirect = () => {
    // if (!donor_id) return

    let url = `/donors-detail/${donor_id}`

    if (bag_id) {
      url = `/master-register/bag-detail/${bag_id}`
    }

    // if (bag_number) {
    //   url = url.concat(`?query=${bag_number}`)
    // }

    history.push(url)
  }

  return (
    <ToolTips title={bag_number || bag_id ? 'Click to Donor Trail' : 'Click to Donor Profile'}>
      <div
        style={{
          cursor: 'pointer',
          color: '#198ae3', // typical hyperlink blue color
          //   textDecoration: 'underline', // underline text to mimic hyperlink
          fontStyle: 'italic',
          ...style
        }}
        onClick={handleRedirect}
      >
        {children}
      </div>
    </ToolTips>
  )
}
