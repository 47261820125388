import React, { useCallback, useEffect, useRef, useState } from 'react'
import { debouncing } from '../../helper/CommonAPI'
import { Autocomplete, TextField } from '@mui/material'
import { inputStyles } from '../certification/EditCertificate'

export const extractAddressDetails = addressComponents => {
  const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name

  const district = addressComponents.find(component =>
    component.types.includes('administrative_area_level_3')
  )?.long_name
  const city = addressComponents.find(component => component.types.includes('administrative_area_level_2'))?.long_name

  const locality = addressComponents.find(component => component.types.includes('locality'))?.long_name

  const country = addressComponents.find(component => component.types.includes('country'))?.long_name
  const route = addressComponents.find(component => component.types.includes('route'))?.long_name
  const landmark = addressComponents.find(component => component.types.includes('landmark'))?.long_name
  const sublocality = addressComponents.find(component => component.types.includes('sublocality'))?.long_name

  const postalCode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name

  return { state, district, locality, country, city, postalCode, route, landmark, sublocality }
}

const AutocompleteSearchGoogle = ({ value, setValue, setSelectedAddress }) => {
  const autocompleteService = useRef(null)
  const placesService = useRef(null)
  const [optionsPlaces, setOptionsPlaces] = useState([])

  useEffect(() => {
    if (window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
  }, [])

  const findPlaces = useCallback(
    debouncing(async value => {
      if (autocompleteService.current && value) {
        autocompleteService.current.getPlacePredictions(
          {
            input: value
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setOptionsPlaces(predictions)
            }
          }
        )
      }
    }, 600),
    []
  )

  const handleInputChange = async value => {
    if (value.length > 2) {
      findPlaces(value)
    }
  }

  const handlePlaceSelect = async placeId => {
    if (!placeId) return
    placesService.current = new window.google.maps.places.PlacesService(
      document.createElement('div') // Required but not rendered
    )
    placesService.current.getDetails({ placeId }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = result.address_components || []

        const details = extractAddressDetails(addressComponents)
        setSelectedAddress(details)
      }
    })
  }

  return (
    <Autocomplete
      freeSolo
      options={optionsPlaces}
      size='small'
      getOptionLabel={option => option.description}
      onInputChange={(e, value, reason) => {
        if (reason === 'reset') return
        setValue(value || '')
        handleInputChange(value)
      }}
      inputValue={value || ''} // Bind the input value to state
      onChange={(e, value) => {
        if (value) {
          // Extract only the area (locality) from the selected place
          const area = value.structured_formatting?.main_text || value.description
          setValue(area || '')
          handlePlaceSelect(value.place_id) // Call your handler
        }
      }}
      renderInput={params => <TextField sx={inputStyles} {...params} placeholder='Type to Search location' />}
    />
  )
}

export default AutocompleteSearchGoogle
