export const CampViewRedirect = ({ camp_id, children }) => {
  return (
    <a
      style={{
        fontStyle: 'italic'
      }}
      href={`/blood-camp-management/view/${camp_id}`}
    >
      {children}
    </a>
  )
}
