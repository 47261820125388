import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'

import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  FormLabel,
  MenuItem,
  IconButton,
  Switch,
  styled,
  Collapse,
  TextField,
  Tooltip
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add, Close } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { bloodGroupArray } from '../../donors/profile/EditProfile'
import AxiosHelper from '../../../helper/AxiosHelper'
import RHFTextField from '../../shared/RHFTextfield'
import RHFSelect from '../../shared/RHFSelect'
import { RHFDatePicker } from '../../shared/RHFDatePicker'
import FormProvider from '../../shared/FormProvider'
import { bloodComponent } from '../../donor-inventory/data'
import Loader from '../../shared/Loader'
import dayjs from 'dayjs'
import BillingModal from '../model/BillingModal'
import ScrollBar from 'react-perfect-scrollbar'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { requestType } from '../new-request/AddBloodReqForm'
import { checkInvoiceHelper, checkTenantId, getInvoicePrefixHelper, parseRequestId } from '../../../helper/CommonAPI'
import { useAppContext } from '../../../AppContext'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import { use } from 'react'
import { RHFDateTimePicker } from '../../shared/RHFDateTimePicker'
const initialComp = {
  item_name: '',
  service_type: '',
  rate: '',
  quantity: '',
  category: 'Component'
}

const initialCompItemAdd = {
  item_name: '',
  service_type: 'FULL',
  category: 'Other',
  rate: '',
  quantity: '',
  amount: ''
}

const defaultValues = {
  invoice_number: '',
  invoice_date: dayjs(),
  invoice_prefix: '',
  component_details: [initialComp],
  items: [],
  request_type: '',
  blood_group: '',
  discount_reason: '',
  persion_name: '',
  component_wise: false,
  components_total_amount: 0,
  items_total_amount: 0,
  total_amount: 0,
  is_previous_bill: false,
  previous_due: 0,
  deletedComponent: [],
  delComps: [],
  isComponentUpdateRequest: false
}
export const LetterMessage = 'Must be less than 50 characters'
const schema = yup.object().shape({
  req_id: yup.string().required('Request Order Id is required'),
  patient_name: yup.string().required('Patient name is required').max(50, LetterMessage),
  hospital_name: yup.string().required('Hospital name is required'),
  cross_match_blood_group: yup.string(),
  blood_group: yup.string(),
  sample_id: yup.string(),
  component_details: yup.array().of(
    yup.object().shape({
      item_name: yup.string().required('Component is required'),
      service_type: yup.string().required('Service Type is required'),
      quantity: yup.string().required('Quantity is required').max(10, LetterMessage),
      amount: yup.string().required('Amount is required').max(10, LetterMessage),
      rate: yup.string().required('Rate is required'),
      category: yup.string()
    })
  ),
  components_total_amount: yup.number(),
  items_total_amount: yup.number(),
  discount: yup
    .string()
    .notRequired()
    .test('discount', 'Discount cannot exceed the total amount', function (value) {
      const { components_total_amount, items_total_amount } = this.parent
      const totalAmount = parseFloat(components_total_amount) + parseFloat(items_total_amount)
      return value === null || parseFloat(value) <= totalAmount || value === '' || value == 0 || value === undefined
    })
})

const itemList = [
  'Anti HBc',
  'Antibody screening (patient)',
  'Platelet Additive Solutions (PAS)',
  'SDP - Double Dose - 1st Unit (Same Patient)',
  'SDP - Double Dose - 2nd Unit (Same Patient)',
  'SDP - Double Dose - 1st / 2nd Unit (Other Patient',
  'Bacterial detection',
  'Container Charges',
  'Chemiluminescence',
  'Grouping and cross matching by automation',
  'Grouping and cross matching by semi automation',
  'IV Generation ELISA (HBsAg)',
  'IV Generation ELISA (HCV)',
  'IV Generation ELISA (HIV)',
  'Irradiation',
  'Leuco filtration Platelets',
  'Leuco filtration Red cells',
  'NAT',
  'Phenotypic for extended serology',
  'Plasma Processing Charges (Buffy Coat Method)',
  'Platelets Processing Charges (Buffy Coat Method)',
  'Processing Charges',
  'Red Cells Processing Charges (Buffy Coat Method)',
  'Reservation Charges (Grouping and cross matching)',
  'Transfer Bag'
]

export const addInMenuList = {
  background: '#007bff',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#007bffbd'
  },
  '&.Mui-selected': {
    backgroundColor: '#007bff',
    '&:hover': {
      backgroundColor: '#007bffbd'
    }
  }
}

export const IOSSwitch = styled(props => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#214F8A',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
)

export const radioStyles = {
  ' &.Mui-checked': {
    color: '#214F8A'
  }
}

export const inputMultiStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },

  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

const componentMapping = [
  { component: 'Whole Blood (CPD)', value: 'WB(CPD)' },
  { component: 'Whole Blood (CPDA-1)', value: 'WB(CPDA-1)' },
  { component: 'Fresh Frozen Plasma', value: 'FFP' },
  { component: 'Single Donor Platelet', value: 'SDP' },
  { component: 'Packed Red Blood Cells', value: 'PRBC' },
  { component: 'Cryoprecipitate', value: 'Cryoprecipitate' },
  { component: 'Plasma', value: 'Plasma' },
  { component: 'Platelet Concentrate', value: 'Platelet Concentrate' },
  { component: 'Cryo Poor Plasma', value: 'Cryo Poor Plasma' },
  { component: 'Random Donor Platelets', value: 'Random Donor Platelets' },
  { component: 'Platelets additive solutions', value: 'Platelets additive solutions' }
]

const Billing = ({ setStepForm, setbilldata, billdata }) => {
  const [isLoading, setLoading] = useState(false)
  const [disAthority, setDisAthority] = useState([])
  const [isFullPaymentDone, setIsFullPaymentDone] = useState(false)
  const [isdilague, setIsDilague] = useState(false)
  const [isMessage, setIsMessage] = useState(false)
  const [previousDetail, setPreviousDetail] = useState([])
  const [compDetail, setCompDetail] = useState([])
  const [addItem, setAddItem] = useState(initialCompItemAdd)
  const [addItemError, setAddItemError] = useState(initialCompItemAdd)
  const [discountSec, setDiscountSec] = useState(false)
  const [isInvoiceAvailable, setIsInvoiceAvailable] = useState(false)
  const [selectComp, setSelectComp] = useState([])
  const [statusData, setStatusData] = useState('')
  const [addprice, setAddPrice] = useState([])
  const [info, setInfo] = useState({})
  const { id } = useParams()
  const [prevDetail, setPrevDetail] = useState()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const history = useHistory()
  const { bloodGroups: bloodGroupArray } = useAppContext()

  // ** Hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    setFocus,
    formState: { errors }
  } = methods

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const getAthority = () => {
    setLoading(true)
    let url = 'billing/authority'
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(res => {
        let dataa = res?.data?.data
        setDisAthority(dataa)
        setLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setLoading(false)
      })
  }

  const handleNewAthority = data => {
    setLoading(true)
    AxiosHelper.post('billing/authority', data)
      .then(res => {
        getAthority()
        setLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setLoading(false)
      })
  }

  const handleItemChange = e => {
    const selectedName = e.target.value
    const selectedItem = addprice.find(item => item.name === selectedName)
    if (!selectedItem) {
      setAddItem({
        ...addItem,
        item_name: selectedName,
        rate: ''
      })
    } else {
      setAddItem({
        ...addItem,
        item_name: selectedName,
        rate: statusData === 'CUSTOM' ? selectedItem.ItemTenantEntities[0].price : selectedItem.govt_price || ''
      })
    }
    setAddItemError({ ...addItemError, item_name: false })
  }

  let compp = []
  selectComp.forEach((item, index) => {
    if (item?.component) {
      compp.push(item?.component)
    }
  })
  const item = { components: compp }
  const matchedItems = addprice.filter(itemData => item.components.includes(itemData.name))

  const getComponentRate = component => {
    if (!component) return null
    const matchedItem = addprice.find(itemData => itemData.name === component)
    if (matchedItem) {
      return statusData === 'CUSTOM' ? matchedItem.ItemTenantEntities[0].price : matchedItem.govt_price
    }
  }

  const getComponentAmount = (unit, component) => {
    let totalrem = 0
    if (!component && !unit) return null
    const matchedItem = addprice.find(itemData => itemData.name === component)
    if (matchedItem) {
      const amount = statusData === 'CUSTOM' ? matchedItem.ItemTenantEntities[0].price : matchedItem.govt_price
      totalrem = unit * amount
      return totalrem
    }
    return null
  }

  const getComponentPriceData = (component, prevAmount) => {
    if (!component) return null
    const matchedItem = addprice.find(itemData => itemData.name === component)
    if (matchedItem) {
      return statusData === 'CUSTOM'
        ? matchedItem.ItemTenantEntities[0].price - prevAmount
        : matchedItem.govt_price - prevAmount
    }
    return null
  }

  const getComponentremAmount = (unit, component, preAmount) => {
    let totalpaid = 0
    if (!component && !unit) return null
    const matchedItem = addprice.find(itemData => itemData.name === component)
    if (matchedItem) {
      const amount = statusData === 'CUSTOM' ? matchedItem.ItemTenantEntities[0].price : matchedItem.govt_price
      totalpaid = unit * amount - unit * preAmount
      return totalpaid
    }
    return null
  }

  const getComponentPrice = async () => {
    try {
      const data = await AxiosHelper.get('setting/tenant-item')
      setAddPrice(data?.data?.data?.response)
      setStatusData(data?.data?.data?.status)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  useEffect(() => {
    getComponentPrice()
  }, [])

  function transformData(data) {
    return data.map(item => {
      return {
        ...item,
        item_name: item?.component,
        component: item?.component,
        volume: item?.volume,
        amount: getComponentAmount(item.unit, item?.component),
        rate: getComponentRate(item?.component),
        quantity: item.unit,
        pricing: statusData,
        category: 'Component'
      }
    })
  }
  function transformComponentData(data) {
    // Create a lookup map from the componentMapping array
    const componentLookup = componentMapping.reduce((lookup, item) => {
      lookup[item.component] = item.value
      return lookup
    }, {})

    // Check if data is an array
    if (!Array.isArray(data)) {
      throw new Error('Invalid data format: Data should be an array.')
    }

    // Initialize a counter for aliquots
    let aliquotCounter = {}

    // Iterate through each item in the data array
    data.forEach((item, index) => {
      try {
        const componentName = item.item_name

        if (!componentName) {
          throw new Error('Component name is missing.')
        }

        // Get the mapped value or use the component name if not found
        const mappedValue = componentLookup[componentName] || componentName
        const acronym = mappedValue

        // Initialize the counter for this component if not already done
        if (!aliquotCounter[acronym]) {
          aliquotCounter[acronym] = 1
        } else {
          aliquotCounter[acronym]++
        }

        // Update the component key with the new value
        item.item_name = `${acronym} Aliquot ${String.fromCharCode(64 + aliquotCounter[acronym])}`
        item.component = item.component
        item.volume = item.volume[index]
        item.is_aliqout = true
      } catch (error) {
        console.error(`Error processing item with component "${item.item_name}": ${error.message}`)
      }
    })

    return data
  }

  const getInfo = newid => {
    if (id) {
      setLoading(true)
      AxiosHelper.get(`billing-v2/${id}`)
        .then(res => {
          handleDetails(res?.data?.data)
          let result = JSON.parse(res?.data?.data?.component_details)
          setSelectComp(result || [])
          setLoading(false)
        })
        .catch(error => {
          console.log('error', error)
          setLoading(false)
        })
    } else {
      setLoading(true)
      AxiosHelper.get(`billing-v2/${newid}`)
        .then(res => {
          handleDetails(res?.data?.data)
          let result = JSON.parse(res?.data?.data?.component_details)
          setSelectComp(result || [])
          setLoading(false)
        })
        .catch(error => {
          setValue('component_details', [initialComp])
          reset({
            patient_name: '',
            hospital_name: '',
            blood_group: '',
            component_details: [initialComp],
            sample_id: '',
            request_type: '',
            cross_match_blood_group: '',
            invoice_date:dayjs(),
          })

          console.log('error', error)
          setLoading(false)
        })
    }
  }
  const handleDetails = dataa => {
    let latestPayment = {}
    let previous_due = 0
    let isAfterPayment = false
    let compdata = JSON.parse(dataa?.component_details)
    let comp = transformData(compdata)
    setInfo(dataa)
    let invNum = ''

    const newArr = duplicateAndModify(comp)
    if (dataa?.is_aliqout && !(dataa?.BillingEntities && dataa?.BillingEntities?.length > 0)) {
      comp = transformComponentData(newArr)
    }

    if (dataa?.BillingEntities && dataa?.BillingEntities?.length === 0) {
      invNum = getPerfix()
    }

    if (dataa?.BillingEntities && dataa?.BillingEntities?.length > 0) {
      latestPayment = dataa?.BillingEntities[dataa?.BillingEntities?.length - 1]
      invNum = latestPayment?.invoice_number
      //  pervv(dataa?.BillingEntities[0])
      // prevTotal(dataa?.BillingEntities[0])
      setPreviousDetail(dataa?.BillingEntities[0])

      if (
        dataa?.BillingEntities[0]?.is_due_amount &&
        dataa?.BillingEntities[0]?.due_amount &&
        !dataa?.BillingEntities[0]?.is_completed
      ) {
        previous_due = dataa?.BillingEntities[0]?.due_amount ? dataa?.BillingEntities[0]?.due_amount : 0
      }
    }
    if (latestPayment && latestPayment?.is_completed === false) {
      comp = filterAndUpdateBillingItemLogs(latestPayment)
      isAfterPayment = true
    }

    setCompDetail(comp)
    const reqInv = parseRequestId(invNum)
    reset({
      invoice_prefix: reqInv.prefix || '',
      invoice_number: reqInv.request_id || '',
      req_id: dataa?.request_id || '',
      request_id: dataa?.id || '',
      patient_name: dataa?.patient_name || '',
      request_type: dataa?.request_type || '',
      hospital_name: dataa?.hospital_name || '',
      blood_group: dataa?.BloodGroupingEntity?.blood_group
        ? dataa?.BloodGroupingEntity?.blood_group
        : dataa?.blood_group
          ? dataa?.blood_group
          : '',
      component_details: comp?.length > 0 ? comp : isAfterPayment ? comp : [initialComp],
      sample_id: dataa?.sample_id || '',
      cross_match_blood_group: dataa?.cross_match_blood_group || '',
      deletedComponent: [],
      delComps: [],
      isComponentUpdateRequest: false,
      items: [],
      previous_due: previous_due,
      latestPayment: latestPayment,
      invoice_date:dayjs(),
    })
    calculateSubtotal()
  }

  function filterAndUpdateBillingItemLogs(data) {
    // Filter BillingItemLogs with the specified criteria
    const filteredLogs = data.BillingItemLogs.filter(
      log => log.service_type === 'Cross Match' && log.category === 'Component'
    )

    // Update the service_type to "FULL" for the filtered logs and map to the new structure
    const updatedLogs = filteredLogs.map(log => {
      log.prev_amount = log.amount ? log.amount : 0
      log.rate = getComponentPriceData(log.item_name, log.prev_amount)
      log.amount = getComponentremAmount(log.quantity, log.item_name, log.prev_amount)
      log.pricing = statusData
      log.from_previousBill = true
      log.service_type = log.service_type === 'Cross Match' ? 'FULL' : log.service_type ? log.service_type : ''
      return {
        pricing: log.pricing,
        prev_amount: log.prev_amount,
        rate: log.rate,
        quantity: log.quantity,
        amount: log.amount,
        from_previousBill: log.from_previousBill,
        item_name: log.item_name,
        service_type: log.service_type,
        category: log.category
      }
    })

    return updatedLogs
  }

  const getPerfix = async () => {
    try {
      const { data } = await getInvoicePrefixHelper('PT', dayjs(watch('invoice_date')).format('YYYY-MM-DD'))
      const { invoice_number, invoice_prefix } = data
      if (invoice_number) {
        setValue('invoice_number', invoice_number)
      }
      if (invoice_prefix) {
        setValue('invoice_prefix', invoice_prefix)
      }

      const invNum = `${invoice_prefix}-${invoice_number}`

      return invNum
    } catch (error) {
      toast.error(error?.message || 'Something went wrong!')
      console.log(error)
    }
  }

  useEffect(() => {
    if (id && !billdata.request_id) {
      getInfo()
    } else {
      const invNum = parseRequestId(getPerfix())
      console.log(`invoice num -> `, invNum)
      setValue('invoice_prefix', invNum.prefix)
      setValue('invoice_number', invNum.request_id)
    }
  }, [id, watch('req_id')])

  useEffect(() => {
    getAthority()
    if (billdata?.request_id) {
      setLoading(true)
      const invNum = parseRequestId(previousDetail?.invoice_number)
      reset({
        invoice_prefix: invNum.prefix || '',
        invoice_number: invNum.request_id || '',
        req_id: billdata?.req_id || '',
        request_id: billdata?.request_id || '',
        patient_name: billdata?.patient_name || '',
        hospital_name: billdata?.hospital_name || '',
        request_type: billdata?.request_type || '',
        blood_group: billdata?.blood_group || '',
        component_details: billdata?.component_details,
        sample_id: billdata?.sample_id || '',
        discount_reason: billdata?.discount_reason || '',
        persion_name: billdata?.persion_name || '',
        remarks: billdata?.remarks || '',
        discount: billdata?.discount || '',
        prevoius_payment_mode: billdata?.prevoius_payment_mode || '',
        cross_match_blood_group: billdata?.cross_match_blood_group || '',
        total_amount: billdata?.total_amount || 0,
        components_total_amount: billdata?.components_total_amount || '',
        items: billdata?.items || [],
        items_total_amount: billdata?.items_total_amount || 0,
        previous_due: billdata?.previous_due || 0,
        latestPayment: billdata?.latestPayment || {},
        deletedComponent: billdata?.deletedComponent || [],
        delComps: billdata?.delComps || [],
        isComponentUpdateRequest: billdata?.isComponentUpdateRequest || false,
        invoice_date:dayjs(),
      })

      setLoading(false)
    }
    setValue("invoice_date",dayjs())
  }, [])

  const handleKeyDown = e => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault()
    }
  }

  const handleComponentWise = val => {
    const comparr = getValues('component_details')
    if (val) {
      const componentwise = duplicateAndModify(comparr)
      setValue('component_details', componentwise)
    } else {
      const withoutComp = combineAndModify(comparr)
      setValue('component_details', withoutComp)
    }
    calculateSubtotal()
  }

  function duplicateAndModify(arr) {
    let result = []
    arr.forEach(item => {
      for (let i = 0; i < item.quantity; i++) {
        result.push({
          ...item,
          // prev_amount: item?.prev_amount ? item?.prev_amount : item?.amount ? item?.amount : "",
          rate: '',
          amount: '',
          quantity: 1
        })
      }
    })
    return result
  }

  function combineAndModify(arr) {
    let result = []
    arr.forEach(item => {
      let existing = result.find(element => element.item_name === item.item_name)
      if (existing) {
        existing.quantity += 1
      } else {
        result.push({ ...item })
      }
    })
    return result
  }

  const onSubmit = data => {
    const bodyData = {
      ...data,
      invoice_number: `${watch('invoice_prefix')}-${watch('invoice_number')}`
    }
    console.log(`submit inv`, bodyData)
    setbilldata(bodyData)
    setStepForm(1)
  }

  const calculateSubtotal = () => {
    let subtotal = 0
    let am = getValues('component_details')
    let am_item = getValues('items')
    let comp_amount = 0
    let item_amount = 0
    if (am_item) {
      am_item.forEach(ele => {
        let amou = parseFloat(ele?.amount)
        if (!Number.isNaN(amou)) {
          subtotal = subtotal + amou
          item_amount = item_amount + amou
        }
      })
    }

    am.forEach(ele => {
      let amou = parseFloat(ele?.amount)
      if (!Number.isNaN(amou)) {
        subtotal = subtotal + amou
        comp_amount = comp_amount + amou
      }
    })

    setValue('components_total_amount', comp_amount)
    setValue('items_total_amount', item_amount)
  }

  const handleRate = (e, index) => {
    let unitttt = `component_details.${index}.quantity`
    setValue(e.target.name, e.target.value)
    let amount = parseFloat(e.target.value)
    let totalAmount = 0
    const unitt = getValues(unitttt)
    let unit = parseFloat(unitt)
    if (unit && amount) {
      totalAmount = unit * amount
    }
    setValue(`component_details.${index}.amount`, totalAmount)
    calculateSubtotal()
  }

  const closeDialog = () => {
    setIsDilague(false)
  }

  const confirmDialog = newpersiondetails => {
    setIsDilague(false)
    handleNewAthority(newpersiondetails)
  }

  const checkInvoiceNumber = async () => {
    try {
      const invNum = `${watch('invoice_prefix')}-${watch('invoice_number')}`

      if (previousDetail?.invoice_number === invNum) {
        setIsInvoiceAvailable(false)
        return
      }

      const { data } = await checkInvoiceHelper(invNum)
      setIsInvoiceAvailable(data)
    } catch (error) {
      toast.error(error?.message || 'Something went wrong!')
    }
  }

  useEffect(() => {
    const comp_total = watch('components_total_amount') ? parseFloat(watch('components_total_amount')) : 0
    const itm_total = watch('items_total_amount') ? parseFloat(watch('items_total_amount')) : 0
    const dis_total = watch('discount') ? parseFloat(watch('discount')) : 0
    const prev_due = watch('previous_due') ? parseFloat(watch('previous_due')) : 0
    let total = comp_total + itm_total - dis_total + prev_due
    setValue('total_amount', total || 0)
  }, [watch('components_total_amount'), watch('discount'), watch('items_total_amount'), watch('previous_due')])

  const today = new Date()

  const addNewItemInBill = newItemDetails => {
    if (newItemDetails?.rate && newItemDetails?.quantity && newItemDetails?.item_name && newItemDetails?.category) {
      let total_price = parseFloat(newItemDetails?.quantity) * parseFloat(newItemDetails?.rate)
      const newItem = { ...newItemDetails, amount: total_price, service_type: 'FULL' }
      let prevDetail = getValues('items')
      if (prevDetail) {
        prevDetail.push(newItem)
      } else {
        prevDetail = [newItem]
      }

      setValue('items', prevDetail)
      setAddItem(initialCompItemAdd)
      calculateSubtotal()
    } else {
      if (!newItemDetails?.rate) {
        setAddItemError({ ...addItemError, rate: true })
      }
      if (!newItemDetails?.quantity) {
        setAddItemError({ ...addItemError, quantity: true })
      }
      if (!newItemDetails?.item_name) {
        setAddItemError({ ...addItemError, item_name: true })
      }
      if (!newItemDetails?.category) {
        setAddItemError({ ...addItemError, category: true })
      }
    }
  }

  const removeExtraItem = index => {
    const items = [...watch('items')]
    items.splice(index, 1)
    setValue('items', items)
    calculateSubtotal()
  }

  const removeComponentFromBill = index => {
    const comp_details = [...watch('component_details')]
    if (comp_details?.length > 1) {
      setValue('isComponentUpdateRequest', true)
      const delElement = comp_details[index]
      const totaldel = [...watch('delComps'), delElement]
      setValue('delComps', totaldel)
      if (delElement?.is_aliqout) {
        const data = consolidateComponents(totaldel)
        setValue('deletedComponent', data)
      } else {
        const data = consolidateQuantities(totaldel)
        setValue('deletedComponent', data)
      }
      comp_details.splice(index, 1)
      setValue('component_details', comp_details)
      calculateSubtotal()
    } else {
      toast.error('you need atlest 1 component in list')
    }
  }

  function consolidateComponents(data) {
    const result = {}

    data.forEach(item => {
      if (item.is_aliqout) {
        const component = item.component
        if (!result[component]) {
          result[component] = {
            component: component,
            volume: []
          }
        }
        result[component].volume.push(item.volume)
      }
    })

    // Convert the result object back to an array of objects and add the unit property
    return Object.values(result).map(item => ({
      component: item.component,
      volume: item.volume,
      unit: item.volume.length
    }))
  }
  function consolidateQuantities(data) {
    const result = {}

    data.forEach(item => {
      const component = item.component
      if (!result[component]) {
        result[component] = {
          component: component,
          unit: 0
        }
      }
      result[component].unit += item.quantity
    })

    // Convert the result object back to an array of objects
    return Object.values(result)
  }
  useEffect(() => {
    if (previousDetail && previousDetail?.id && !previousDetail?.is_completed) {
      // const data = comparepreviousBill(previousDetail?.BillingItemLogs)
      // setValue("component_details", data);
      setValue('is_previous_bill', true)
      setIsMessage(true)

      setPrevDetail(previousDetail)
    }
    if (previousDetail?.is_completed) {
      setIsFullPaymentDone(true)
      setIsMessage(true)
    }
  }, [previousDetail])

  const handleResetComponent = () => {
    setValue('component_details', compDetail)
    setValue('items', [])
    setValue('delComps', [])
    setValue('deletedComponent', [])
    setValue('isComponentUpdateRequest', false)
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <BillingModal closeDialog={closeDialog} open={isdilague} confirmDialog={confirmDialog} />
      <div className='bloodbank_action  mb-2'>
        <p>Billing Detail</p>
      </div>
      <Alert
        // variant={isFullPaymentDone?'danger':  "info"}
        className={isFullPaymentDone ? 'alert-danger' : 'alert-info'}
        show={isMessage}
        onClose={() => setIsMessage(false)}
        dismissible
      >
        {isFullPaymentDone ? (
          <>
            <Alert.Heading>Payment Already Done!</Alert.Heading>
            <Alert.Heading>Invoice No. : {previousDetail?.invoice_number || 'NA'}</Alert.Heading>
          </>
        ) : (
          <>
            <Alert.Heading>Invoice No. : {previousDetail?.invoice_number || 'NA'}</Alert.Heading>
            <Alert.Heading>
              <div style={{ display: 'flex', gap: '16px' }}>
                <div>
                  Previous Amount Paid : &#x20B9;
                  {previousDetail?.amount || previousDetail?.amount === 0 ? previousDetail?.amount : '-'}
                </div>
                <div>
                  Due Amount: &#x20B9;
                  {previousDetail?.due_amount || previousDetail?.due_amount === 0 ? previousDetail?.due_amount : 'NA'}
                </div>
              </div>
            </Alert.Heading>
          </>
        )}
      </Alert>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>Request Order Id</FormLabel>
            <RHFTextField
              name='req_id'
              size='small'
              fullWidth
              sx={inputStyles}
              placeholder={'Enter Request Order Id'}
              onChange={e => {
                getInfo(e.target.value)
                setValue('req_id', e.target.value)
              }}
              // InputProps={InputEndProps}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Invoice Number <span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField
              disabled={!!previousDetail?.invoice_number}
              name='invoice_number'
              sx={inputStyles}
              onChange={e => {
                setValue('invoice_number', e.target.value)
                if (e.target.value) {
                  checkInvoiceNumber()
                }
              }}
              InputProps={{
                startAdornment: (
                  <TextField
                    name='invoice_prefix'
                    size='small'
                    value={(watch('invoice_prefix') || 'Prefix N/A') + ' -'}
                    variant='standard'
                    sx={{ minWidth: '50px', margin: '0px 8px 0px 0px', position: 'relative', top: '2px' }}
                    disabled
                    InputProps={{
                      disableUnderline: true // Disable the underline
                    }}
                  />
                )
              }}
            />
            {isInvoiceAvailable && watch('invoice_number').length > 0 ? (
              <FormHelperText sx={{ color: 'error.main' }}>Invoice Number not available</FormHelperText>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>Date & Time</FormLabel>
            <RHFDateTimePicker
              name='invoice_date'
              disableFuture
              ampm={false}
              format='DD/MM/YYYY HH:mm'
              onChange={val => {
                setValue('invoice_date', dayjs(val))
                getPerfix()
              }}
              sx={inputStyles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>Reason Of Request</FormLabel>
            <RHFSelect
              size='small'
              name='request_type'
              fullWidth
              sx={inputStyles}
              disabled
              //  value={"attendee_relation"}
              //   onChange={(e)=>{setValue(e.target.value)}}
              // InputProps={InputEndProps}
            >
              {requestType.length > 0 ? (
                requestType?.map((rel, i) => (
                  <MenuItem key={i} value={rel}>
                    {rel}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No Relation Found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>Patient Name</FormLabel>
            <RHFTextField
              size='small'
              name='patient_name'
              fullWidth
              sx={inputStyles}
              disabled
              // InputProps={InputEndProps}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>Hospital Name</FormLabel>
            <RHFTextField
              name='hospital_name'
              size='small'
              fullWidth
              sx={inputStyles}
              disabled
              // onChange={handleId}
              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>Sample Id</FormLabel>
            <RHFTextField
              size='small'
              name='sample_id'
              fullWidth
              sx={inputStyles}
              disabled

              // InputProps={InputEndProps}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Blood Group of Patient
            </FormLabel>
            <RHFSelect
              size='small'
              name='blood_group'
              fullWidth
              sx={inputStyles}
              disabled
              // InputProps={InputEndProps}
            >
              {bloodGroupArray?.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>

          {watch('cross_match_blood_group')?.length > 0 ? (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormLabel sx={labelStyles}>Cross matching Blood Group</FormLabel>
              <RHFTextField
                size='small'
                name='cross_match_blood_group'
                fullWidth
                sx={inputStyles}
                disabled

                // InputProps={InputEndProps}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Box
              sx={{
                borderRadius: '8px',
                background: '#F4F1F1',
                padding: '16px'
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ fontSize: '16px', fontWeight: '700' }}>Billing Details</Box>
                <IconButton
                  color='error'
                  sx={{
                    display: watch('component_details')?.length > 0 ? 'flex' : 'none',
                    width: 'fit-content',
                    borderRadius: '8px',
                    fontSize: '14px'
                  }}
                  onClick={handleResetComponent}
                >
                  <RestartAltIcon /> Reset All
                </IconButton>
              </Box>

              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IOSSwitch
                  checked={watch('component_wise')}
                  onChange={e => {
                    setValue('component_wise', e.target.checked)
                    handleComponentWise(e.target.checked)
                  }}
                />
                <FormLabel sx={{ ...labelStyles, ml: 2 }}>Unit/Component Wise</FormLabel>
              </Box>

              <Box
                rowGap={3}
                columnGap={2}
                display='grid'
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: '3fr 3fr 3fr 3fr 1fr'
                }}
                sx={{
                  marginTop: 2,
                  alignItems: 'start',
                  marginBottom: 5
                }}
              >
                <Box>
                  <FormLabel sx={labelStyles}>Item Name</FormLabel>
                  <RHFSelect
                    name={`item_name`}
                    size='small'
                    fullWidth
                    sx={inputStyles}
                    value={addItem?.item_name}
                    onChange={e => handleItemChange(e)}
                    // onChange={(e) => { setAddItem({ ...addItem, item_name: e.target.value }); setAddItemError({ ...addItemError, item_name: false }) }}
                  >
                    {itemList?.map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  {addItemError?.item_name ? (
                    <FormHelperText sx={{ color: 'error.main' }}>Item Name is required</FormHelperText>
                  ) : null}
                </Box>

                <Box>
                  <FormLabel sx={labelStyles}>Quantity</FormLabel>
                  <RHFTextField
                    size='small'
                    name={`quantity`}
                    placeholder={'Enter Item Quantity'}
                    fullWidth
                    type='number'
                    sx={inputStyles}
                    value={addItem?.quantity}
                    onChange={e => {
                      setAddItem({ ...addItem, quantity: e.target.value })
                      setAddItemError({ ...addItemError, quantity: false })
                    }}
                  />
                  {addItemError?.quantity ? (
                    <FormHelperText sx={{ color: 'error.main' }}>Quantity is required</FormHelperText>
                  ) : null}
                </Box>
                <Box>
                  <FormLabel sx={labelStyles}>Rate (₹)</FormLabel>
                  <RHFTextField
                    size='small'
                    name={`rate`}
                    fullWidth
                    placeholder={'Enter Item Rate'}
                    type='number'
                    sx={inputStyles}
                    value={addItem?.rate}
                    onChange={e => {
                      setAddItem({ ...addItem, rate: e.target.value })
                      setAddItemError({ ...addItemError, rate: false })
                    }}
                  />
                  {addItemError?.rate ? (
                    <FormHelperText sx={{ color: 'error.main' }}>Rate is required</FormHelperText>
                  ) : null}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-start'
                    }
                    // alignItems: 'center'
                  }}
                >
                  <IconButton
                    color='error'
                    sx={{
                      //  display: watch('component_details')?.length > 0 ? 'flex' : 'none',
                      width: 'fit-content',
                      borderRadius: '8px',
                      marginTop: '25px'
                    }}
                    onClick={() => addNewItemInBill(addItem)}
                  >
                    <Add sx={{ color: 'green' }} />
                  </IconButton>

                  <IconButton
                    color='error'
                    sx={{
                      // display: watch('component_details')?.length > 0 ? 'flex' : 'none',
                      width: 'fit-content',
                      borderRadius: '8px',
                      marginTop: '25px'
                    }}
                    onClick={() => setAddItem(initialCompItemAdd)}
                  >
                    <Close icon='material-symbols:close' />
                  </IconButton>
                </Box>
              </Box>

              <div className='table-responsive mt-2 border-top'>
                <ScrollBar>
                  <table className='table  table-hover'>
                    <thead>
                      <tr className='bloodrequisition-tr'>
                        <th>Item name</th>
                        <th>Service Type</th>
                        <th>Quantity</th>
                        <th>Rate (₹)</th>
                        <th>Price (₹)</th>
                        <th style={{ width: '50px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {watch('component_details')?.map((field, index) => (
                        <tr key={index}>
                          <td>
                            <RHFTextField
                              size='small'
                              type='text'
                              name={`component_details.${index}.item_name`}
                              fullWidth
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              // InputProps={InputEndProps}
                              disabled
                            />
                            {watch(`component_details.${index}.is_aliqout`) &&
                              watch(`component_details.${index}.volume`) && (
                                <FormHelperText>Volume:{watch(`component_details.${index}.volume`)}</FormHelperText>
                              )}
                          </td>

                          <td>
                            <RHFSelect
                              key={index}
                              name={`component_details.${index}.service_type`}
                              // name={field['service_type']}
                              size='small'
                              fullWidth
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              // InputProps={InputEndProps}
                              disabled={watch(`component_details.${index}.from_previousBill`) === true ? true : false}
                            >
                              <MenuItem value='Cross Match'>Cross Match</MenuItem>
                              <MenuItem value='FULL'>FULL</MenuItem>
                            </RHFSelect>
                            {watch(`component_details.${index}.prev_amount`) && (
                              <FormHelperText>
                                cross match fee already paid:&#x20B9;{watch(`component_details.${index}.prev_amount`)}
                              </FormHelperText>
                            )}
                          </td>

                          <td>
                            <RHFTextField
                              size='small'
                              type='number'
                              name={`component_details.${index}.quantity`}
                              fullWidth
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              // InputProps={InputEndProps}
                              disabled
                            />
                          </td>
                          <td>
                            <RHFTextField
                              size='small'
                              name={`component_details.${index}.rate`}
                              fullWidth
                              type='number'
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              onKeyDown={handleKeyDown}
                              onChange={e => handleRate(e, index)}
                              // InputProps={InputEndProps}
                            />

                            {watch(`component_details.${index}.pricing`) && (
                              <Tooltip
                                title='Set Custom Pricing'
                                className='cursor-pointer'
                                style={{ color: '#214f89' }}
                              >
                                <FormHelperText onClick={() => history.push('/admin-settings/component-pricing')}>
                                  {watch(`component_details.${index}.pricing`)}
                                </FormHelperText>
                              </Tooltip>
                            )}
                          </td>
                          <td>
                            <RHFTextField
                              size='small'
                              name={`component_details.${index}.amount`}
                              fullWidth
                              type='number'
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              disabled
                              // InputProps={InputEndProps}
                            />
                          </td>
                          <td style={{ width: '50px' }}>
                            <IconButton
                              color='error'
                              sx={{
                                display: watch(`component_details.${index}.prev_amount`)
                                  ? 'none'
                                  : watch('component_details')?.length > 1
                                    ? 'flex'
                                    : 'none',
                                width: 'fit-content',
                                borderRadius: '8px'
                              }}
                              onClick={() => removeComponentFromBill(index)}
                            >
                              <Close icon='material-symbols:close' />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                      {watch('items')?.map((field, index) => (
                        <tr>
                          <td>
                            <RHFSelect
                              name={`items.${index}.item_name`}
                              size='small'
                              fullWidth
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              // InputProps={InputEndProps}
                              disabled
                            >
                              {itemList?.map((item, i) => (
                                <MenuItem value={item} key={i}>
                                  {item}
                                </MenuItem>
                              ))}
                            </RHFSelect>
                          </td>
                          <td>
                            <RHFSelect
                              name={`items.${index}.service_type`}
                              size='small'
                              fullWidth
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              disabled
                              // InputProps={InputEndProps}
                            >
                              <MenuItem value='Cross Match'>Cross Match</MenuItem>
                              <MenuItem value='FULL'>FULL</MenuItem>
                            </RHFSelect>
                          </td>
                          <td>
                            <RHFTextField
                              size='small'
                              type='number'
                              name={`items.${index}.quantity`}
                              fullWidth
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              // InputProps={InputEndProps}
                              disabled
                            />
                          </td>
                          <td>
                            <RHFTextField
                              size='small'
                              name={`items.${index}.rate`}
                              fullWidth
                              type='number'
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              disabled
                            />
                          </td>

                          <td>
                            <RHFTextField
                              size='small'
                              name={`items.${index}.amount`}
                              fullWidth
                              type='number'
                              sx={{
                                ...inputStyles,
                                minWidth: '100px'
                              }}
                              disabled
                              // InputProps={InputEndProps}
                            />
                          </td>
                          <td style={{ width: '50px' }}>
                            <IconButton
                              color='error'
                              sx={{
                                // display: watch('component_details')?.length > 0 ? 'flex' : 'none',
                                width: 'fit-content',
                                borderRadius: '8px'
                              }}
                              onClick={() => removeExtraItem(index)}
                            >
                              <Close icon='material-symbols:close' />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollBar>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={5}>
            <Box
              sx={{
                padding: '16px',
                borderRadius: '8px',
                background: '#FFF',
                boxShadow: '5px 5px 24px 0px rgba(63, 81, 181, 0.10)'
              }}
            >
              <Grid item xs={12}>
                <Box
                  style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                  onClick={() => setDiscountSec(!discountSec)}
                >
                  <FormLabel
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#07101C',
                      mb: 3
                    }}
                  >
                    Special Discount Detail
                  </FormLabel>

                  {discountSec ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Box>
              </Grid>
              <Collapse in={discountSec}>
                <Grid item xs={12}>
                  <FormLabel sx={labelStyles}>
                    Special Discount (₹)
                    {/* <span className="text-danger">*</span> */}
                  </FormLabel>
                  <RHFTextField
                    name='discount'
                    size='small'
                    fullWidth
                    type='number'
                    placeholder={'Enter Special Discount In Rupee'}
                    sx={inputStyles}
                    onChange={e => {
                      setValue('discount', e.target.value)
                    }}
                    // InputProps={InputEndProps}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <FormLabel sx={labelStyles}>
                    Person Name
                    {/* <span className="text-danger">*</span> */}
                  </FormLabel>

                  <RHFSelect
                    size='small'
                    name='persion_name'
                    placeholder={'Enter Person Name'}
                    fullWidth
                    sx={inputStyles}
                    //  value={"attendee_relation"}
                    onChange={e => {
                      if (e.target.value !== 'Other') {
                        setValue('persion_name', e.target.value)
                      } else {
                        setValue('persion_name', '')
                      }
                    }}
                    // InputProps={InputEndProps}
                  >
                    {disAthority?.map(ele => (
                      <MenuItem value={ele.name}>{ele.name}</MenuItem>
                    ))}
                    <MenuItem
                      value='Other'
                      onClick={() => {
                        setIsDilague(true)
                      }}
                      sx={addInMenuList}
                    >
                      <Add /> Add New Person
                    </MenuItem>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <FormLabel sx={labelStyles}>
                    Reason of Discount
                    {/* <span className="text-danger">*</span> */}
                  </FormLabel>
                  <RHFSelect
                    size='small'
                    name='discount_reason'
                    fullWidth
                    sx={inputStyles}
                    //   onChange={(e)=>{setValue(e.target.value)}}
                    // InputProps={InputEndProps}
                  >
                    <MenuItem value='Vip'>VIP</MenuItem>
                    <MenuItem value='Doctor'> Doctor</MenuItem>
                    <MenuItem value='Politician'> Politician</MenuItem>
                    <MenuItem value='Other'> Other</MenuItem>
                    <MenuItem value='VBD/DD'>VBD/DD</MenuItem>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <FormLabel sx={labelStyles}>
                    Remarks
                    {/* <span className="text-danger">*</span> */}
                  </FormLabel>
                  <RHFTextField
                    name='remarks'
                    size='small'
                    placeholder={'Enter remarks'}
                    fullWidth
                    sx={inputStyles}
                    // onChange={handleId}
                    // InputProps={InputEndProps}
                  />
                </Grid>
              </Collapse>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={7}>
            <Box
              sx={{
                borderRadius: '8px',
                border: '1px solid #F5F5F5',
                background: '#F2F9F7',
                padding: '16px'
              }}
            >
              <Grid item xs={12}>
                <FormLabel
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#07101C',
                    mb: 3
                  }}
                >
                  Summary
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <div className='row payment-sumary-hding pb-3'>
                  <div className='col'>Description</div>
                  <div className='col d-flex justify-content-end'>Charges</div>
                </div>

                {watch('previous_due') > 0 ? (
                  <div className='row payment-sumary-p pt-3'>
                    <div className='col'>Previous Due Amount</div>
                    <div className='col d-flex justify-content-end'>&#x20B9;{watch('previous_due')}</div>
                  </div>
                ) : null}

                <div className='row payment-sumary-p pt-2'>
                  <div className='col'>Component Charges</div>
                  <div className='col d-flex justify-content-end'>
                    &#x20B9;{watch('components_total_amount') ? watch('components_total_amount') : '0'}
                  </div>
                </div>
                {watch('items')?.map((field, index) => (
                  <div className='row payment-sumary-p pt-2'>
                    <div className='col'>{field?.item_name}</div>
                    <div className='col d-flex justify-content-end'>&#x20B9;{field?.amount}</div>
                  </div>
                ))}

                <div className='row payment-sumary-p pt-2 pb-3'>
                  <div className='col'>Discount</div>
                  <div className='col d-flex justify-content-end'>
                    -&#x20B9;{watch('discount') ? watch('discount') : 0}
                  </div>
                </div>

                <div className='row payment-sumary-t pt-3'>
                  <div className='col'>Total Fee</div>
                  <div className='col d-flex justify-content-end'>
                    &#x20B9;{watch('total_amount') ? watch('total_amount') : 0}
                  </div>
                  <RHFTextField name='total_amount' sx={{ display: 'none' }} />
                </div>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', mb: 5 }}>
            <button
              type='button'
              className='btn backBtn '
              onClick={() => {
                history.goBack()
              }}
            >
              Cancel
            </button>

            {isFullPaymentDone ? (
              <button
                type='submit'
                disabled
                className='btn gradient-btn'
                // onClick={() => setStepForm(1)}
              >
                {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Next'}
              </button>
            ) : (
              <button
                type='submit'
                disabled={isLoading}
                className='btn gradient-btn'
                // onClick={() => setStepForm(1)}
              >
                {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Next'}
              </button>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}

export default Billing
