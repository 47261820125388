import { useEffect, useState } from 'react'
import BreadCrumb from '../../common/BreadCrumb'
import PageHeader from '../../common/PageHeader'
import Loader from '../../shared/Loader'
import ScrollCard from '../../shared/ScrollCard'
import AxiosHelper from '../../../helper/AxiosHelper'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BloodBagInfo } from '../../donors/profile/utils/ui/blood-bag'
import { AllottedComponent } from '../../donors/profile/utils/ui/allotted-component'
import { TTI } from '../../donors/profile/utils/ui/tti'
import { GroupingInfo } from '../../donors/profile/utils/ui/grouping'
import { PersonalTrail } from '../../donors/profile/PersonalTrail'
import { HealthRecord } from '../../donors/profile/utils/ui/health-record'
import { MedicalHistory } from '../../donors/profile/utils/ui/medical-history'
import { calTimeLine } from '../../../helper/Validator'
import { DonorInfoSection } from '../../donors/profile/utils/ui/donor-info-section'

const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/master-register',
    label: 'Master Register'
  },
  {
    path: '/',
    label: 'Bag Detail'
  }
]

export const getBagDetail = async id => {
  const response = await AxiosHelper.get(`master-register/${id}`)
  return response.data
}

export const getDonorHistory = async donor_id => {
  const response = await AxiosHelper.get(`donor/donation-history/${donor_id}`)
  return response.data
}

export const getDonorInfo = async donor_id => {
  const response = await AxiosHelper.get(`donor/info/${donor_id}`)
  return response.data
}

export const BagDetail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [bagDetail, setBagDetail] = useState()
  const { id } = useParams()
  const [printOptions, setPrintOptions] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [trailData, setTrailData] = useState(null)
  const [basicData, setBasicData] = useState()
  const [singleTimeline, setSingleTimeline] = useState({})
  const [openTimeline, setOpenTimeline] = useState(false)
  const [result, setResult] = useState()

  const HealthStatus = [
    {
      label: 'Hemoglobin',
      value: `${trailData?.hemoglobin_level} g/dl`
    },
    {
      label: 'Weight',
      value: `${trailData?.weight} kg`
    },
    {
      label: 'Blood Pressure ',
      value: `${trailData?.blood_pressure} mm Hg`
    },
    {
      label: 'Temperature',
      value: `${trailData?.temprature}\u00b0 F`
    },
    {
      label: 'Pulse',
      value: trailData?.pulse
    },
    {
      label: 'HB Done By',
      value: trailData?.counseller
    }
  ]

  const fetchBagDetail = async () => {
    try {
      setIsLoading(true)
      if (!id) throw new Error('Bag ID is required')

      const { data } = await getBagDetail(id)
      if (!data) throw new Error('Bag detail not found')
      setBagDetail(data)
    } catch (error) {
      toast.error(error?.message || "Can't fetch bag detail")
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDonorHistory = async () => {
    try {
      setIsLoading(true)
      const donor_id = bagDetail?.DonorScreening?.abha_id
      if (!donor_id) {
        throw new Error('This bag has no donor')
      }
      const { data } = await getDonorHistory(donor_id)

      const currBag = data?.find(item => item?.BloodBagIssueEntity?.id == id)

      if (!currBag) throw new Error('Bag not found in donor history')
      setTrailData(currBag)
      setResult(JSON.parse(currBag.declration))
    } catch (error) {
      // toast.error(error?.message || "Can't fetch donor history")
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDonorInfo = async () => {
    try {
      setIsLoading(true)
      const donor_id = bagDetail?.DonorScreening?.abha_id
      if (!donor_id) {
        throw new Error('Donor ID is required')
      }
      const { data } = await getDonorInfo(donor_id)
      if (!data) {
        throw new Error('Donor info not found')
      }
      setBasicData(data)
    } catch (error) {
      // toast.error(error?.message || "Can't fetch donor info")
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const hasDonorScreening = () => {
    if (!bagDetail || !bagDetail?.DonorScreening) return false

    return true
  }

  const updateCampInfo = async (camp_id, bag_id, new_date) => {
    const { data } = await AxiosHelper.post(`donor-v2/change-donor-camp`, { camp_id, bag_id, new_date })

    return data
  }

  const updateDonationType = async (abha_id, donation_type) => {
    const { data } = await AxiosHelper.post(`donor-v2/change-donor-type`, { abha_id, donation_type })

    return data
  }

  const saveEditModal = async ({ camp_id, bag_id, new_date, abha_id, donation_type }) => {
    try {
      if (camp_id && bag_id) {
        let updated_camp_id = camp_id !== "in_house" ? camp_id : null

        const { data } = await updateCampInfo(updated_camp_id, bag_id, new_date)
        if (!data) {
          throw new Error('No data found')
        }
        await fetchDonorHistory()
      } else if (abha_id && donation_type) {
        const { data } = await updateDonationType(abha_id, donation_type)
        if (!data) {
          throw new Error('No data found')
        }
        await fetchBagDetail()
      } else {
        throw new Error('Invalid data')
      }
    } catch (error) {
      console.log(error)
      toast.error(error || 'Something went wrong')
    }
  }

  const data = {
    id: trailData?.id,
    // bag_number: trailData?.BloodBagIssueEntity?.bag_number,
    // bag_type: trailData?.BloodBagIssueEntity?.bag_type,
    // tube_number: trailData?.BloodBagIssueEntity?.tube_number,
    // bag_id: trailData?.BloodBagIssueEntity?.bag_id,

    ...bagDetail,
    is_defered: trailData?.is_defered,
    // createdAt: trailData?.createdAt,
    // updatedAt: trailData?.updatedAt,
    screening_date: trailData?.screening_date,
    abha_id: trailData?.abha_id,
    BloodTestingEntity: hasDonorScreening()
      ? trailData?.BloodBagIssueEntity?.BloodTestingEntity
      : trailData?.BloodTestingEntity,
    BloodLogsEntities: hasDonorScreening()
      ? trailData?.BloodBagIssueEntity?.BloodLogsEntities
      : trailData?.BloodLogsEntities,
    PatientDonorEntity: trailData?.PatientDonorEntity,
    certificateDeliverLogEntities: trailData?.BloodBagIssueEntity?.certificateDeliverLogEntities,
    is_bulk: !hasDonorScreening()
  }

  useEffect(() => {
    fetchBagDetail()
  }, [])

  useEffect(() => {
    if (bagDetail) {
      fetchDonorHistory()
      fetchDonorInfo()
    }

    if (!trailData && bagDetail) {
      setTrailData(bagDetail)
    }
  }, [bagDetail])

  useEffect(() => {
    setSingleTimeline(calTimeLine(trailData, basicData))
  }, [trailData, basicData])

  console.log(`trail data i -> `, bagDetail?.DonorScreening?.PatientDonorEntity?.donation_type)

  return (
    <>
      <Loader isLoading={isLoading} />
      <BreadCrumb breadcrumbRoute={breadcrumbRoute}>
        <PageHeader title='Bag Detail' />
      </BreadCrumb>
      <ScrollCard normal>
        <div className='row'>
          {bagDetail?.DonorScreening?.abha_id ? (
            <DonorInfoSection
              fetchBagDetail={fetchBagDetail}
              saveEditModal={saveEditModal}
              data={{
                ...basicData,
                ...trailData,
                donation_type: bagDetail?.DonorScreening?.PatientDonorEntity?.donation_type
              }}
            />
          ) : null}
          <div className='col-12 col-lg-6 col-xl-6'>
            <div className='shadowCell p-2 mb-3'>
              <BloodBagInfo
                trailData={trailData}
                setPrintOptions={setPrintOptions}
                setOpenModal={setOpenModal}
                openModal={openModal}
                printOptions={printOptions}
                data={data}
                basicData={basicData}
              />
            </div>
            {bagDetail?.BloodLogsEntities
              ? bagDetail?.BloodLogsEntities.map((item, idx) => {
                  if (
                    item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.is_deleted === true ||
                    item?.BloodRequestLogsEntity?.is_deleted === true
                  )
                    return null
                  return (
                    <AllottedComponent
                      getAllotedComponents={fetchBagDetail}
                      setLoading={setIsLoading}
                      setAllotedComp={setBagDetail}
                      allotedComp={bagDetail}
                      item={item}
                      trailData={trailData}
                      key={idx}
                    />
                  )
                })
              : null}
            {(trailData?.BloodBagIssueEntity?.BloodTestingEntity || bagDetail?.BloodTestingEntity) &&
            hasDonorScreening() ? (
              <div className='shadowCell p-2 mb-3'>
                <div>
                  <TTI
                    trailData={trailData}
                    BloodTestingEntity={
                      hasDonorScreening()
                        ? trailData?.BloodBagIssueEntity?.BloodTestingEntity
                        : bagDetail?.BloodTestingEntity
                    }
                  />
                </div>
              </div>
            ) : null}
            {hasDonorScreening() ? (
              <div className='shadowCell p-2 mb-3'>
                <div>
                  <GroupingInfo
                    trailData={trailData}
                    BloodBagStageGroupingEntity={bagDetail?.BloodBagStageGroupingEntity}
                    data={trailData}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className='col-12 col-lg-6 col-xl-6'>
            {(trailData?.BloodBagIssueEntity?.BloodTestingEntity || bagDetail?.BloodTestingEntity) &&
            !hasDonorScreening() ? (
              <div className='shadowCell p-2 mb-3'>
                <div>
                  <TTI
                    trailData={trailData}
                    BloodTestingEntity={
                      hasDonorScreening()
                        ? trailData?.BloodBagIssueEntity?.BloodTestingEntity
                        : bagDetail?.BloodTestingEntity
                    }
                  />
                </div>
              </div>
            ) : null}

            {!hasDonorScreening() ? (
              <div className='shadowCell p-2 mb-3'>
                <div>
                  <GroupingInfo
                    trailData={trailData}
                    BloodBagStageGroupingEntity={bagDetail?.BloodBagStageGroupingEntity}
                    data={trailData}
                  />
                </div>
              </div>
            ) : null}

            {hasDonorScreening() ? (
              <PersonalTrail
                userData={trailData}
                timelineData={singleTimeline}
                openTimeline={openTimeline}
                setOpenTimeline={setOpenTimeline}
              />
            ) : null}

            {hasDonorScreening() ? (
              <div className='shadowCell p-2 mb-3'>
                <div>
                  <HealthRecord trailData={trailData} HealthStatus={HealthStatus} />
                </div>
              </div>
            ) : null}

            {hasDonorScreening() ? (
              <div className='shadowCell p-2 mb-3'>
                <div>
                  <MedicalHistory result={result} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </ScrollCard>
    </>
  )
}
